import React, { useEffect, useState } from "react";
import baseUrl from "../Configs";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CloseIcon from "@mui/icons-material/Close";
import { Snackbar, Alert } from "@mui/material";
import DayRating from "./DayRating";
import AdditionalRating from "./AdditonalRating";
import SubmitDayRating from "./SubmitDayRating";
import ExamRating from "./ExamRating";
import AddHomework from "./AddHomework";
import CreateNewExam from "./CreateNewExam";
import EditExistingExam from "./EditExistingExam";
import LiveSyllabus from "./LiveSyllabus";
import ShowTimeTable from "./ShowTimeTable";
import { useRecoilState } from "recoil";

import {
  teacherSelectedClassState,
  teacherSelectedSectionState,
  teacherSelectedSubjectState,
  teacherSelectedExamState,
  headerSelectorsState,
} from "../Atoms";

import "../Css/Basics.css";
import AddAttendance from "../Teacher-app/AddAttendance";
import Header from "../Header";
import "./Css/TeacherHome.css";
import AddNotification from "./AddNotification";

const TeacherHome = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isLiveSyllabusOpen, setIsLiveSyllabusOpen] = useState(false);
  const [showSyllabusSnackbar, setShowSyllabusSnackbar] = useState(false);

  const [selectedClass, setSelectedClass] = useRecoilState(
    teacherSelectedClassState
  );
  const [selectedSection, setSelectedSection] = useRecoilState(
    teacherSelectedSectionState
  );
  const [selectedSubject, setSelectedSubject] = useRecoilState(
    teacherSelectedSubjectState
  );

  const [headerSelectors, setHeaderSelectors] =
    useRecoilState(headerSelectorsState);

  const handleButtonClick = (option) => {
    setSelectedOption(option);
  };

  const handleBackClick = () => {
    setSelectedOption(null);
  };

  const openLiveSyllabus = () => {
    if (selectedClass && selectedSection && selectedSubject) {
      setIsLiveSyllabusOpen(true);
    } else {
      // Show the snackbar if values are not selected
      setShowSyllabusSnackbar(true);
    }
  };

  const handleCloseSyllabusSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSyllabusSnackbar(false);
  };
  const closeLiveSyllabus = () => {
    setIsLiveSyllabusOpen(false);
  };

  let options = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape" && isLiveSyllabusOpen) {
        closeLiveSyllabus();
      }
    };

    if (isLiveSyllabusOpen) {
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isLiveSyllabusOpen]); // Dependency array ensures effect runs when isLiveSyllabusOpen changes

  useEffect(() => {
    setHeaderSelectors({
      classSelector: true,
      sectionSelector: true,
      subjectSelector: true,
      dateSelector: true,
      backMethod: selectedOption ? handleBackClick : null,
    });
  }, [selectedOption]);

  return (
    <div>
      <Header
        selectors={{
          classSelector: true,
          sectionSelector: true,
          subjectSelector: true,
          dateSelector: true,
        }}
      />

      <div className="teacherhome-container">
        {(() => {
          switch (selectedOption) {
            case "Show Time Table":
              return (
                <>
                  <div className="teacher-home_secondary-header blue-button">
                    <button
                      className="teacher-home_back-button"
                      onClick={handleBackClick}
                    >
                      <ArrowBackIosIcon /> Back
                    </button>
                    <span>Time Table</span>
                  </div>
                  <ShowTimeTable onSubmit={() => setSelectedOption(null)} />
                </>
              );
            case "Take Attendance":
              return (
                <>
                  <div className="teacher-home_secondary-header blue-button">
                    <button
                      className="teacher-home_back-button"
                      onClick={handleBackClick}
                    >
                      <ArrowBackIosIcon /> Back
                    </button>
                    <span>Add Attendance</span>
                  </div>
                  <AddAttendance
                    onSubmit={() => setSelectedOption(null)}
                    handleBackClick={() => handleBackClick()}
                  />
                </>
              );
            case "Add Daily Rating":
              return (
                <>
                  <div className="teacher-home_secondary-header blue-button">
                    {/* Back Button */}
                    <button
                      className="teacher-home_back-button"
                      onClick={handleBackClick}
                    >
                      <ArrowBackIosIcon /> Back
                    </button>
                    <span>Add Daily Rating</span>
                    {/* Live Syllabus Button */}
                    <button
                      className="teacher-home_back-button live-syllabus-button"
                      onClick={openLiveSyllabus}
                    >
                      <span className="star-icon">⭐</span>
                      <span className="star-icon">⭐</span>
                      <span className="star-icon">⭐</span>
                      <span className="star-icon">⭐</span>
                      <span className="star-icon">⭐</span>
                      <span className="star-icon">⭐</span>
                      <span className="star-icon">⭐</span>
                      <span className="star-icon">⭐</span>
                      <span className="star-icon">⭐</span>
                      <span className="star-icon">⭐</span>
                      Live Syllabus
                    </button>
                  </div>
                  <DayRating />
                  <SubmitDayRating onSubmit={() => setSelectedOption(null)} />
                </>
              );
            case "Add Additional Rating":
              return (
                <>
                  <div className="teacher-home_secondary-header blue-button">
                    <button
                      className="teacher-home_back-button"
                      onClick={handleBackClick}
                    >
                      <ArrowBackIosIcon /> Back
                    </button>
                    <span>Add Additional Rating</span>
                  </div>
                  <AdditionalRating
                    onSubmit={() => setSelectedOption(null)}
                    handleBackClick={() => handleBackClick()}
                  />
                </>
              );
            case "Add Exam Rating":
              return (
                <>
                  <div className="teacherhome-sectionHeader">
                    <div className="teacher-home_secondary-header blue-button">
                      <button
                        className="teacher-home_back-button"
                        onClick={handleBackClick}
                      >
                        <ArrowBackIosIcon /> Back
                      </button>
                      <span>Add Exam Rating</span>
                    </div>
                  </div>
                  <ExamRating onSubmit={() => setSelectedOption(null)} />
                </>
              );

            case "Create New Exam":
              return (
                <>
                  {/* <button onClick={handleBackClick}>Back</button> */}
                  <CreateNewExam
                    onSubmit={() => setSelectedOption(null)}
                    handleBackClick={() => handleBackClick()}
                  />
                </>
              );
            case "Edit Existing Exam":
              return (
                <>
                  {/* <button onClick={handleBackClick}>Back</button> */}
                  <EditExistingExam
                    onSubmit={() => setSelectedOption(null)}
                    handleBackClick={() => handleBackClick()}
                  />
                </>
              );

            case "Add Homework":
              return (
                <>
                  <div className="teacher-home_secondary-header blue-button">
                    <button
                      className="teacher-home_back-button"
                      onClick={handleBackClick}
                    >
                      <ArrowBackIosIcon /> Back
                    </button>
                    <span>Add Homework</span>
                  </div>
                  <AddHomework onSubmit={() => setSelectedOption(null)} />
                </>
              );
            case "Add Notification":
              return (
                <>
                  <div className="teacher-home_secondary-header blue-button">
                    <button
                      className="teacher-home_back-button"
                      onClick={handleBackClick}
                    >
                      <ArrowBackIosIcon /> Back
                    </button>
                    <span>Add Notification</span>
                  </div>
                  <AddNotification onSubmit={() => setSelectedOption(null)} />
                </>
              );

            default:
              return (
                <div className="teacherhome-options-container">
                  <div
                    className="blue-button"
                    onClick={() => handleButtonClick("Take Attendance")}
                  >
                    Take Attendance
                  </div>
                  <div
                    className="blue-button"
                    onClick={() => handleButtonClick("Add Daily Rating")}
                  >
                    Add Daily Rating
                  </div>
                  <div
                    className="blue-button"
                    onClick={() => handleButtonClick("Add Additional Rating")}
                  >
                    Add Additional Rating
                  </div>
                  <div
                    className="blue-button"
                    onClick={() => handleButtonClick("Add Exam Rating")}
                  >
                    Add Exam Rating
                  </div>
                  <div
                    className="blue-button"
                    onClick={() => handleButtonClick("Create New Exam")}
                  >
                    Create New Exam
                  </div>
                  <div
                    className="blue-button"
                    onClick={() => handleButtonClick("Edit Existing Exam")}
                  >
                    Edit Existing Exam
                  </div>
                  <div
                    className="blue-button"
                    onClick={() => handleButtonClick("Add Homework")}
                  >
                    Add Homework
                  </div>
                  <div
                    className="blue-button"
                    onClick={() => handleButtonClick("Add Notification")}
                  >
                    Add Notification
                  </div>
                  <div
                    className="blue-button"
                    onClick={() => handleButtonClick("Show Time Table")}
                  >
                    Show Time Table
                  </div>
                </div>
              );
          }
        })()}
      </div>
      {/* Full-Screen Live Syllabus Popup */}
      {isLiveSyllabusOpen && (
        <div className="live-syllabus-popup">
          <div className="live-syllabus-content">
            {/* Close Button */}
            <button className="close-button" onClick={closeLiveSyllabus}>
              <CloseIcon />
            </button>
            {/* Live Syllabus Content */}
            <LiveSyllabus />
          </div>
        </div>
      )}
      {/* Snackbar for Live Syllabus notification */}
      <Snackbar
        open={showSyllabusSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSyllabusSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSyllabusSnackbar}
          severity="warning"
          sx={{ width: "100%" }}
        >
          Please select Class, Section, and Subject to view Live Syllabus.
        </Alert>
      </Snackbar>
    </div>
  );
};

export default TeacherHome;

import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import baseUrl from "./Configs";

const Register = ({ inputRef }) => {
  const [loading, setLoading] = useState(false);
  const [personname, setPersonname] = useState("");
  const navigate = useNavigate();
  const [schoolName, setSchoolName] = useState("");
  const [schoolAffiliationNumber, setSchoolAffiliationNumber] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const registerUrl = `${baseUrl}/v1/schoolroute/addnewschool`;

  const registerSchool = async (event) => {
    event.preventDefault();

    // Check if all fields are filled
    if (
      !schoolName ||
      !schoolAffiliationNumber ||
      !username ||
      !password ||
      !personname
    ) {
      toast.error("All fields are required!");
      return;
    }

    setLoading(true);

    // Timeout for 5s in case of slow network
    const controller = new AbortController();
    const timeoutId = setTimeout(() => {
      controller.abort();
      setLoading(false);
      toast.error("Request timed out. Please try again.");
    }, 5000);

    try {
      const response = await fetch(registerUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          schoolName,
          schoolAffiliationNumber,
          personname,
          username,
          password,
        }),
        signal: controller.signal,
      });

      clearTimeout(timeoutId);
      setLoading(false);

      if (response.ok) {
        toast.success("Registration successful!");
        setTimeout(() => navigate("/thankyou"), 500);
      } else {
        toast.error("Failed to register school. Please try again.");
      }
    } catch (error) {
      if (error.name === "AbortError") return;
      toast.error("An error occurred. Please check your network.");
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        p: 3,
        backgroundColor: "#2c2c2c",
        borderRadius: "8px",
        boxShadow: 3,
        height: "inherit",
      }}
    >
      <Typography variant="h4" sx={{ mb: 2 }} color="white">
        Register
      </Typography>
      <form
        onSubmit={registerSchool}
        style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
      >
        <TextField
          label="School Name"
          variant="outlined"
          value={schoolName}
          onChange={(e) => setSchoolName(e.target.value)}
          fullWidth
          inputRef={inputRef}
          required
        />
        <TextField
          label="School Affiliation Number"
          variant="outlined"
          value={schoolAffiliationNumber}
          onChange={(e) => setSchoolAffiliationNumber(e.target.value)}
          fullWidth
          required
        />
        <TextField
          label="Admin Name"
          variant="outlined"
          value={personname}
          onChange={(e) => setPersonname(e.target.value)}
          fullWidth
          required
        />
        <TextField
          label="Email/Username"
          variant="outlined"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          fullWidth
          required
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          required
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
          sx={{
            opacity: loading ? 0.7 : 1,
          }}
        >
          {loading ? (
            <CircularProgress size={24} sx={{ color: "white" }} />
          ) : (
            "Register"
          )}
        </Button>
      </form>
      <ToastContainer position="top-right" autoClose={3000} />
    </Box>
  );
};

export default Register;

import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Link,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import baseUrl from "./Configs";

export default function LoginForm() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const loginUrl = `${baseUrl}/v1/auth/login`;

  // Verify token if available
  const verifyToken = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const response = await fetch(`${baseUrl}/v1/auth/verify`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          navigate("/super");
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("userRole");
          toast.error("Session expired. Please log in again.", {
            autoClose: 3000,
          });
        }
      } catch (error) {
        console.error("Error verifying token:", error);
        localStorage.removeItem("token");
        localStorage.removeItem("userRole");
        toast.error("Session expired. Please log in again.", {
          autoClose: 300,
        });
      }
    }
  };

  useEffect(() => {
    verifyToken();
  }, []);

  const handleLogin = async (event) => {
    event.preventDefault();

    // Check if fields are empty
    if (!username || !password) {
      toast.error("All fields are required!", { autoClose: 3000 });
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(loginUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({ username, password }),
      });

      setLoading(false);

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("token", data.token);
        localStorage.setItem("userRole", data.user.role);
        navigate("/super");
      } else {
        const errorData = await response.json();
        toast.error("Failed to login: " + errorData.error, { autoClose: 3000 });
      }
    } catch (error) {
      setLoading(false);
      console.error("Network error:", error);
      toast.error("Network error. Please check your internet connection.", {
        autoClose: 3000,
      });
    }
  };

  return (
    <Box
      sx={{
        p: 3,
        backgroundColor: "#2c2c2c",
        borderRadius: "8px",
        color: "white",
        boxShadow: 3,
      }}
    >
      <ToastContainer />
      <Typography variant="h4" sx={{ mb: 2 }}>
        Login
      </Typography>
      <form
        onSubmit={handleLogin}
        style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
      >
        <TextField
          label="Email/Username"
          variant="outlined"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          fullWidth
          required
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          required
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={loading}
          sx={{
            opacity: loading ? 0.7 : 1,
          }}
        >
          {loading ? (
            <CircularProgress size={24} sx={{ color: "white" }} />
          ) : (
            "Login"
          )}
        </Button>
        <Link href="/forgot-password" underline="hover" sx={{ mt: 1 }}>
          Forgot password?
        </Link>
        <Link href="/register" underline="hover" sx={{ mt: 1 }}>
          Don't have an account? Register
        </Link>
      </form>
    </Box>
  );
}

import React from "react";
import { useNavigate } from "react-router-dom";
import HeaderHomepage from "./HeaderHompage";
import { useRecoilValue } from "recoil";
import { userRoleState } from "./Atoms";
import "./Css/SuperUserHome.css";

const SuperUserHome = () => {
  const navigate = useNavigate();
  const navigateToAdminTasks = () => {
    navigate("/admintasks");
  };

  const navigateToTeacherHome = () => {
    navigate("/teacherHome");
  };

  const navigateToClasswiseDetails = () => {
    navigate("/t");
  };
  const navigateToFeeStatus = () => {
    navigate("/feeStatus");
  };
  const navigateToSalaryStatus = () => {
    navigate("/salaryStatus");
  };
  const navigateToCostManagement = () => {
    navigate("/costManagement");
  };

  const navigateToStaffBio = () => {
    navigate("/staffbio");
  };

  const navigateToClasswiseStatus = () => {
    navigate("/classwisestatus");
  };

  const navigateToDownloadRecords = () => {
    navigate("/downloadrecords");
  };

  const navigateToUserManagement = () => {
    navigate("/usermanagement");
  };

  const userRole = localStorage.getItem("userRole");

  return (
    <div>
      <HeaderHomepage />
      <div className="superuserhome-content">
        {userRole === "admin" && (
          <div className="superuserhome-card" onClick={navigateToAdminTasks}>
            Admin Tasks
          </div>
        )}
        {(userRole === "admin" || userRole === "teacher") && (
          <div className="superuserhome-card" onClick={navigateToTeacherHome}>
            Teacher Tasks
          </div>
        )}
        {(userRole === "admin" || userRole === "teacher") && (
          <div
            className="superuserhome-card"
            onClick={navigateToClasswiseDetails}
          >
            Classwise students details
          </div>
        )}
        {(userRole === "admin" || userRole === "accountant") && (
          <div className="superuserhome-card" onClick={navigateToStaffBio}>
            Staff Bio
          </div>
        )}
        {(userRole === "admin" || userRole === "accountant") && (
          <div className="superuserhome-card" onClick={navigateToFeeStatus}>
            Fee Payment
          </div>
        )}
        {(userRole === "admin" || userRole === "accountant") && (
          <div className="superuserhome-card" onClick={navigateToSalaryStatus}>
            Salary Details
          </div>
        )}
        {(userRole === "admin" || userRole === "accountant") && (
          <div
            className="superuserhome-card"
            onClick={navigateToCostManagement}
          >
            Cost Management
          </div>
        )}

        <div className="superuserhome-card" onClick={navigateToDownloadRecords}>
          Download Records
        </div>
        <div className="superuserhome-card" onClick={navigateToClasswiseStatus}>
          Classwise status
        </div>
        {userRole === "admin" && (
          <div
            className="superuserhome-card"
            onClick={navigateToUserManagement}
          >
            User Management
          </div>
        )}
        <div className="superuserhome-card">School Overall Status</div>
      </div>
    </div>
  );
};

export default SuperUserHome;

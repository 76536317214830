import React from "react";
import { useTheme, useMediaQuery, Button } from "@mui/material";

const ReachUs = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (isMobile) {
    return (
      <Button
        variant="contained"
        color="primary"
        href="mailto:support@spellstudy.com"
      >
        Get Help
      </Button>
    );
  }

  return (
    <p>
      Reach us at:{" "}
      <a
        href="mailto:support@spellstudy.com"
        style={{ color: "white", textDecoration: "none" }}
      >
        support@spellstudy.com
      </a>
    </p>
  );
};

export default ReachUs;

import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TextField,
  Button,
  TableRow,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Grid,
  useTheme,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import baseUrl from "./Configs";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  teacherSelectedSectionState,
  teacherSelectedClassState,
  selectedClassIdState,
  headerSelectorsState,
} from "./Atoms";

import Header from "./Header";
import "./Css/StudentFee.css";
import ShowPaymentRecord from "./ShowPaymentRecord";

const getMonthFromIndex = (index) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return months[index] || "Invalid Month";
};

const getLastFeeDetails = (feeStatus, feeType) => {
  let lastFeeDetail = 0;
  let lastFeeMonth = "";
  for (let i = feeStatus.length - 1; i >= 0; i--) {
    const feeDetail = feeStatus[i].feeDetails.find(
      (fee) => fee.feeType === feeType
    );
    if (feeDetail) {
      lastFeeDetail = feeDetail.amountPaid;
      lastFeeMonth = feeStatus[i]?.month;
      break;
    }
  }
  return {
    lastFeeDetail,
    month: lastFeeMonth
      ? getMonthFromIndex(new Date(lastFeeMonth).getMonth())
      : "N/A",
  };
};

const StudentFeeStatus = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);
  const [students, setStudents] = useState({});
  const [openPopup, setOpenPopup] = useState(false);
  const [studentFeeStatus, setStudentFeeStatus] = useState({});
  const [selectedStudentId, setSelectedStudentId] = useState();
  const [feeType, setFeeType] = useState("");
  const [paidAmount, setPaidAmount] = useState("");
  const [paidBy, setPaidBy] = useState("");
  const [receiptNumber, setReceiptNumber] = useState("");
  const [paymentMode, setPaymentMode] = useState("");
  const [selectedClass, setSelectedClass] = useRecoilState(
    teacherSelectedClassState
  );
  const [selectedSection, setSelectedSection] = useRecoilState(
    teacherSelectedSectionState
  );
  const [headerSelectors, setHeaderSelectors] =
    useRecoilState(headerSelectorsState);
  const selectedClassId = useRecoilValue(selectedClassIdState);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [open, setOpen] = useState(false);
  const [monthIndex, setMonthIndex] = useState(4);

  // New state for success dialog
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const optionsForGet = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const getStudentFeeStatusUrl = (studentId) =>
    `${baseUrl}/v1/feeroute/getstudentfeestatus?sti=${studentId}`;
  const getStudentsUrl = `${baseUrl}/v1/classroute/getpartialstudentlist?classId=${selectedClassId}`;
  const payFeeUrl = `${baseUrl}/v1/feeroute/newFeeStatus`;
  const getClassFeeStatusUrl = `${baseUrl}/v1/feeroute/getfeestatusofeachstudentinclass?className=${selectedClass}&section=${selectedSection}&monthIndex=${monthIndex}`;

  const fetchStudents = async () => {
    setLoading(true);
    try {
      const response = await fetch(getStudentsUrl, optionsForGet);
      const data = await response.json();
      setStudents(data);
    } catch (error) {
      console.error("Error fetching students:", error);
    }
    setLoading(false);
  };

  const fetchStudentFeeStatus = async (studentId) => {
    try {
      const response = await fetch(
        getStudentFeeStatusUrl(studentId),
        optionsForGet
      );
      const data = await response.json();
      setStudentFeeStatus(data);
      if (data.feeDetails && data.feeDetails.length > 0 && !feeType) {
        setFeeType(data.feeDetails[0].feeType);
      }
    } catch (error) {
      console.error("Error fetching student fee status:", error);
    }
  };

  // Handle row click for collapse (not for payment record)
  const handleRowClick = (student) => {
    // Toggle collapse for the row
    setOpen((prev) => (selectedStudent === student.id ? !prev : true));
    setSelectedStudent(student.id);
    // Optionally fetch fee status if not already fetched
    fetchStudentFeeStatus(student.id);
  };

  // New function to handle payment record view click
  const handleViewPaymentRecord = (student) => {
    // Fetch the latest fee status for this student
    fetchStudentFeeStatus(student.id);
    // Open the payment record popup (without collapsing the row)
    setSelectedStudent(student.id);
    setOpenPopup(true);
  };

  // Close payment record popup only
  const closePopup = () => {
    setOpenPopup(false);
  };

  const handleFeeTypeChange = (event) => {
    setFeeType(event.target.value);
  };

  const handleAmountChange = (event) => {
    setPaidAmount(event.target.value);
  };

  const handlePaidByChange = (event) => {
    setPaidBy(event.target.value);
  };

  const handlePayFee = async () => {
    try {
      const response = await fetch(payFeeUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify({
          studentId: selectedStudent,
          amount: paidAmount,
          type: feeType,
          paidBy: paidBy,
          receiptNumber: receiptNumber,
          paymentMode: paymentMode,
        }),
      });
      const data = await response.json();
      console.log("Payment response:", data);
      // Clear payment form fields on success
      setPaidAmount("");
      setPaidBy("");
      setReceiptNumber("");
      setPaymentMode("");
      // Refresh fee status
      fetchStudentFeeStatus(selectedStudent);
      // Show success dialog
      setSuccessMessage("Fee payment successful!");
      setSuccessDialogOpen(true);
    } catch (error) {
      console.error("Error paying fee:", error);
    }
  };

  const closeSuccessDialog = () => {
    setSuccessDialogOpen(false);
  };

  useEffect(() => {
    if (selectedClassId) fetchStudents();
  }, [selectedClassId]);

  useEffect(() => {
    setHeaderSelectors({
      classSelector: true,
      sectionSelector: true,
      subjectSelector: false,
      dateSelector: false,
      backMethod: null,
    });
  }, []);
  return (
    <div>
      <Header
        selectors={{
          classSelector: true,
          sectionSelector: true,
          subjectSelector: false,
        }}
      />
      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer
          component={Paper}
          style={{ marginTop: isMobile ? "8px" : "24px" }}
        >
          <Table size={isMobile ? "small" : "medium"}>
            <TableHead>
              <TableRow>
                <TableCell>Roll Number</TableCell>
                <TableCell>Name</TableCell>
                <TableCell align="center">Payment Record</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {students &&
                students.studentList &&
                students.studentList.map((student, index) => (
                  <React.Fragment key={index}>
                    <TableRow
                      onClick={() => handleRowClick(student)}
                      style={{ cursor: "pointer" }}
                      className={
                        selectedStudent === student.id && open
                          ? "sticky-row"
                          : ""
                      }
                    >
                      <TableCell>{student.rollNumber}</TableCell>
                      <TableCell>{student.name}</TableCell>
                      <TableCell align="center">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleViewPaymentRecord(student);
                          }}
                          size={isMobile ? "small" : "medium"}
                        >
                          View Payment Record
                        </Button>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        colSpan={6}
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                      >
                        <Collapse
                          in={open && selectedStudent === student.id}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box margin={isMobile ? 1 : 2}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={6}>
                                <Paper
                                  elevation={2}
                                  style={{ padding: isMobile ? "8px" : "16px" }}
                                >
                                  <Typography
                                    variant={isMobile ? "subtitle1" : "h6"}
                                    gutterBottom
                                  >
                                    Student Fee Details
                                  </Typography>
                                  {studentFeeStatus &&
                                  studentFeeStatus.feeDetails ? (
                                    studentFeeStatus.feeDetails.map(
                                      (fee, idx) => (
                                        <Box
                                          key={idx}
                                          mb={2}
                                          p={1}
                                          border={1}
                                          borderRadius={4}
                                        >
                                          <Typography variant="subtitle2">
                                            Fee Type: {fee.feeType}
                                          </Typography>
                                          <Typography variant="body2">
                                            Default Amount: {fee.defaultAmount}
                                          </Typography>
                                          <Typography variant="body2">
                                            Frequency: {fee.feeFrequency}
                                          </Typography>
                                          <Typography variant="body2">
                                            Description: {fee.description}
                                          </Typography>
                                          {fee.feeFrequency !== "one-time" &&
                                            fee.monthlyFeeDetails &&
                                            fee.monthlyFeeDetails.length >
                                              0 && (
                                              <Box mt={1}>
                                                <Typography
                                                  variant="body2"
                                                  gutterBottom
                                                >
                                                  Monthly Details:
                                                </Typography>
                                                <Table size="small">
                                                  <TableHead>
                                                    <TableRow>
                                                      <TableCell>
                                                        Month
                                                      </TableCell>
                                                      <TableCell>
                                                        Amount
                                                      </TableCell>
                                                    </TableRow>
                                                  </TableHead>
                                                  <TableBody>
                                                    {fee.monthlyFeeDetails.map(
                                                      (md, i) => (
                                                        <TableRow key={i}>
                                                          <TableCell>
                                                            {new Date(
                                                              md.month
                                                            ).toLocaleDateString(
                                                              undefined,
                                                              {
                                                                month: "long",
                                                                year: "numeric",
                                                              }
                                                            )}
                                                          </TableCell>
                                                          <TableCell>
                                                            {md.amount}
                                                          </TableCell>
                                                        </TableRow>
                                                      )
                                                    )}
                                                  </TableBody>
                                                </Table>
                                              </Box>
                                            )}
                                        </Box>
                                      )
                                    )
                                  ) : (
                                    <Typography variant="body2">
                                      No fee details available.
                                    </Typography>
                                  )}
                                </Paper>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <Paper
                                  elevation={2}
                                  style={{ padding: isMobile ? "8px" : "16px" }}
                                >
                                  <Typography
                                    variant={isMobile ? "subtitle1" : "h6"}
                                    gutterBottom
                                  >
                                    Pay Fee
                                  </Typography>
                                  {studentFeeStatus &&
                                    studentFeeStatus.feeDetails && (
                                      <FormControl fullWidth margin="normal">
                                        <InputLabel id="fee-type-label">
                                          Select Fee Type
                                        </InputLabel>
                                        <Select
                                          labelId="fee-type-label"
                                          value={feeType}
                                          onChange={handleFeeTypeChange}
                                        >
                                          {studentFeeStatus.feeDetails.map(
                                            (fee, index) => (
                                              <MenuItem
                                                key={index}
                                                value={fee.feeType}
                                              >
                                                {fee.feeType} (Default:{" "}
                                                {fee.defaultAmount})
                                              </MenuItem>
                                            )
                                          )}
                                        </Select>
                                      </FormControl>
                                    )}
                                  <TextField
                                    label="Amount"
                                    fullWidth
                                    margin="normal"
                                    value={paidAmount}
                                    onChange={handleAmountChange}
                                    size={isMobile ? "small" : "medium"}
                                  />
                                  <TextField
                                    label="Paid by"
                                    fullWidth
                                    margin="normal"
                                    value={paidBy}
                                    onChange={handlePaidByChange}
                                    size={isMobile ? "small" : "medium"}
                                  />
                                  <TextField
                                    label="Receipt Number"
                                    fullWidth
                                    margin="normal"
                                    value={receiptNumber}
                                    onChange={(e) =>
                                      setReceiptNumber(e.target.value)
                                    }
                                    size={isMobile ? "small" : "medium"}
                                  />
                                  <FormControl fullWidth margin="normal">
                                    <InputLabel id="payment-mode-label">
                                      Select Payment Mode
                                    </InputLabel>
                                    <Select
                                      labelId="payment-mode-label"
                                      value={paymentMode}
                                      onChange={(e) =>
                                        setPaymentMode(e.target.value)
                                      }
                                    >
                                      <MenuItem value={"Cash"}>Cash</MenuItem>
                                      <MenuItem value={"UPI"}>UPI</MenuItem>
                                      <MenuItem value={"Online"}>
                                        Online
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                  {studentFeeStatus &&
                                    studentFeeStatus.feeStatus && (
                                      <Box mt={2}>
                                        <Typography variant="body2">
                                          Last paid {feeType}:{" "}
                                          {
                                            getLastFeeDetails(
                                              studentFeeStatus.feeStatus,
                                              feeType
                                            ).lastFeeDetail
                                          }{" "}
                                          for{" "}
                                          {
                                            getLastFeeDetails(
                                              studentFeeStatus.feeStatus,
                                              feeType
                                            ).month
                                          }
                                        </Typography>
                                      </Box>
                                    )}
                                  <Box mt={2}>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={handlePayFee}
                                      fullWidth
                                      size={isMobile ? "small" : "medium"}
                                    >
                                      Pay Fee
                                    </Button>
                                  </Box>
                                </Paper>
                              </Grid>
                            </Grid>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {/* Payment Record Popup */}
      <Dialog open={openPopup} onClose={closePopup} maxWidth="lg" fullWidth>
        <DialogTitle>
          Fee Details for {studentFeeStatus?.feeDetails?.name || "Student"}
        </DialogTitle>
        <DialogContent>
          {studentFeeStatus ? (
            <ShowPaymentRecord feeData={studentFeeStatus.feeStatus} />
          ) : (
            <Typography>No fee details available.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closePopup} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* Success Dialog */}
      <Dialog
        open={successDialogOpen}
        onClose={closeSuccessDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Payment Successful</DialogTitle>
        <DialogContent>
          <Typography>{successMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeSuccessDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default StudentFeeStatus;

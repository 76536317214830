import React, { useRef } from "react";
import {
  Container,
  Grid,
  Box,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import RegisterHeader from "./RegisterHeader";
import Register from "./Register";
import LoginForm from "./LoginForm";
import AiLoginScreen from "./AiLoginScreen";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: { main: "#00e5ff" },
    secondary: { main: "#ff4081" },
    background: { default: "#0d1117", paper: "#1c1e1e" },
    text: { primary: "#ffffff", secondary: "#bdbdbd" },
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
    h1: { fontSize: "3rem", fontWeight: "bold", letterSpacing: "2px" },
    h4: { fontSize: "2rem", fontWeight: "bold" },
    h5: { fontSize: "1.5rem", fontWeight: "500" },
  },
});

const EntryPage = () => {
  const registerInputRef = useRef(null);

  // This function scrolls to the Register section and focuses the first field
  const handleGetStarted = () => {
    if (registerInputRef.current) {
      registerInputRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      // Wait a bit for the scroll animation to complete, then focus the input
      setTimeout(() => {
        registerInputRef.current.focus();
      }, 500);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{ backgroundColor: "rgb(39,105,123)", minHeight: "100vh", p: 2 }}
      >
        <Container maxWidth="xl">
          <RegisterHeader />
          <Grid container spacing={4} sx={{ mt: 3, alignItems: "stretch" }}>
            {/* Left Column: Registration and Login Forms */}
            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 3,
                  flex: 1,
                }}
              >
                <LoginForm />
                <Box
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <Register inputRef={registerInputRef} />
                </Box>
              </Box>
            </Grid>

            {/* Right Column: Futuristic AI Screen */}
            <Grid item xs={12} md={6} sx={{ display: "flex" }}>
              <AiLoginScreen onGetStarted={handleGetStarted} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default EntryPage;

import React, { useEffect } from "react";
import baseUrl from "./Configs";
import { useRecoilValue, useRecoilState } from "recoil";
import Header from "./Header";
import {
  teacherSelectedClassState,
  teacherSelectedSectionState,
  teacherSelectedDateState,
  headerSelectorsState,
} from "./Atoms";
import { Card, CardContent, Typography, Button } from "@mui/material";

const DownloadRecords = () => {
  const selectedClass = useRecoilValue(teacherSelectedClassState);
  const selectedSection = useRecoilValue(teacherSelectedSectionState);
  const selectedDate = useRecoilValue(teacherSelectedDateState);

  const [headerSelectors, setHeaderSelectors] =
    useRecoilState(headerSelectorsState);

  // Convert the selected date into a "YYYY-MM" formatted string.
  const monthStr = selectedDate
    ? new Date(selectedDate).toISOString().substring(0, 7)
    : "";

  const handleDownload = async () => {
    try {
      const response = await fetch(
        `${baseUrl}/v1/classroute/download-attendance-excel?className=${selectedClass}&section=${selectedSection}&month=${monthStr}`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to download the file");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      // Create a temporary <a> element to trigger the download
      const a = document.createElement("a");
      a.href = url;
      a.download = `Attendance_${selectedClass}_${selectedSection}_${monthStr}.xlsx`; // Adjust file name as needed
      document.body.appendChild(a);
      a.click();

      // Cleanup
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Download failed:", error);
    }
  };

  useEffect(() => {
    setHeaderSelectors({
      classSelector: true,
      sectionSelector: true,
      subjectSelector: false,
      dateSelector: true,
      backMethod: null,
    });
  }, []);

  return (
    <div>
      <Header />
      <Card sx={{ maxWidth: 500, margin: "20px auto" }}>
        <CardContent>
          <Typography variant="h5" component="div">
            Download Attendance Records
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
            <strong>Class:</strong> {selectedClass || "N/A"} &nbsp;&nbsp;
            <strong>Section:</strong> {selectedSection || "N/A"} &nbsp;&nbsp;
            <strong>Month:</strong> {monthStr || "N/A"}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={handleDownload}
            disabled={!selectedClass || !selectedSection || !monthStr}
          >
            Download Attendance
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default DownloadRecords;

import React from "react";
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Privacy Policy for SpellStudy
      </Typography>
      <Typography variant="subtitle1" align="center" gutterBottom>
        <strong>Effective Date:</strong> April 1, 2025 |{" "}
        <strong>Last Updated:</strong> April 1, 2025
      </Typography>
      <Typography variant="body1" paragraph>
        At SpellStudy we respects your privacy and is committed to protecting
        the information of all users, including children under 13. This Privacy
        Policy explains how we use and protect your information when you use our
        mobile application.
      </Typography>

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">1. Information We Use</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box component="ul" sx={{ pl: 2 }}>
            <li>
              Provide core educational services (attendance, homework, report
              cards, etc.)
            </li>
            <li>
              Facilitate communication between teachers, parents, and students
            </li>
            <li>Improve app functionality and performance</li>
            <li>Ensure child safety and prevent unauthorized access</li>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">2. Children’s Privacy</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box component="ul" sx={{ pl: 2 }}>
            <li>
              Student information is managed by schools under their supervision.
            </li>
            <li>
              The app does not contain ads, in-app purchases, or third-party
              tracking for children.
            </li>
            <li>
              Parents can request access, correction, or deletion of their
              child's data by contacting us at support@spellstudy.com .
            </li>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">3. Data Sharing and Security</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            We <strong>do not sell or share</strong> information with third
            parties for marketing purposes.
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            a. Who We Share Data With
          </Typography>
          <Box component="ul" sx={{ pl: 2, mb: 2 }}>
            <li>
              <strong>Schools & Teachers:</strong> To manage student records and
              performance
            </li>
            <li>
              <strong>Parents:</strong> To provide updates on student progress
            </li>
            <li>
              <strong>Service Providers:</strong> For hosting, analytics, and
              security, bound by strict confidentiality agreements
            </li>
          </Box>
          <Typography variant="subtitle1" gutterBottom>
            b. How We Protect Data
          </Typography>
          <Box component="ul" sx={{ pl: 2 }}>
            <li>
              We use encryption, secure databases, and access controls to
              safeguard your data.
            </li>
            <li>
              Only authorized users (school administrators, teachers, and
              parents) can access student information.
            </li>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">4. Parental Rights and Controls</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box component="ul" sx={{ pl: 2 }}>
            <li>
              Parents can review or request deletion of their child’s
              information.
            </li>
            <li>
              Schools managing student accounts are responsible for obtaining
              necessary parental consent.
            </li>
            <li>
              To request data access, correction, or deletion, contact us at
              support@spellstudy.com .
            </li>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">
            5. Third-Party Links and Services
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            Our app <strong>does not</strong> contain third-party advertisements
            or external links. However, it may integrate with educational
            services authorized by schools.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">
            6. Changes to This Privacy Policy
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            We may update this policy from time to time. Any changes will be
            communicated through the app or our website. Continued use of the
            app after changes implies acceptance of the updated policy.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">7. Contact Us</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            If you have any questions about this Privacy Policy or data
            protection, contact us at:
          </Typography>
          <Typography variant="body1">
            <strong>Email:</strong> support@spellstudy.com
          </Typography>
          <Typography variant="body1">
            By using SpellStudy, you agree to this Privacy Policy.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default PrivacyPolicy;

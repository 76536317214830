import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tabs,
  Tab,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import baseUrl from "../Configs";
import { useRecoilState } from "recoil";
import { headerSelectorsState } from "../Atoms";

const ShowTimeTable = ({ onSubmit }) => {
  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const [selectedDay, setSelectedDay] = useState("Monday");
  const [loading, setLoading] = useState(false);
  const [timetableData, setTimetableData] = useState([]);
  const [teacherNames, setTeacherNames] = useState({});
  const [timeSlots, setTimeSlots] = useState([]);

  const [headerSelectors, setHeaderSelectors] =
    useRecoilState(headerSelectorsState);

  // API endpoints
  const getTimeTableUrl = `${baseUrl}/v1/schoolroute/gettimetable`;
  const getAllTeachersUrl = `${baseUrl}/v1/staffroute/getallteachers`;

  // Options for API calls
  const optionsForGet = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  // Fetch teacher data on component mount
  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        const teachersResponse = await fetch(getAllTeachersUrl, optionsForGet);
        const teachersData = await teachersResponse.json();
        if (teachersData) {
          const teacherMap = {};
          // Use teacher._id instead of teacher.id
          teachersData.forEach((teacher) => {
            teacherMap[teacher.id] = teacher.name;
          });
          setTeacherNames(teacherMap);
        }
      } catch (err) {
        console.error("Error fetching teachers:", err);
      }
    };

    fetchTeachers();
  }, []);

  // Fetch timetable data when day changes
  useEffect(() => {
    const fetchTimetable = async () => {
      setLoading(true);
      try {
        const response = await fetch(getTimeTableUrl, optionsForGet);
        const data = await response.json();

        if (data.success) {
          // Filter timetables for selected day and sort by class name
          const dayTimetables = data.data
            .filter((item) => item.day === selectedDay)
            .sort((a, b) => {
              const classNameA = `${a.className} ${a.section}` || "";
              const classNameB = `${b.className} ${b.section}` || "";
              return classNameA.localeCompare(classNameB);
            });

          // Get all unique time slots across all classes
          const allTimeSlots = new Set();
          dayTimetables.forEach((timetable) => {
            timetable.periods.forEach((period) => {
              allTimeSlots.add(`${period.startTime}-${period.endTime}`);
            });
          });

          // Convert to array and sort by start time
          const sortedTimeSlots = Array.from(allTimeSlots)
            .map((slot) => {
              const [start, end] = slot.split("-");
              return { startTime: start, endTime: end };
            })
            .sort((a, b) => {
              return a.startTime.localeCompare(b.startTime);
            });

          setTimeSlots(sortedTimeSlots);
          setTimetableData(dayTimetables);
        }
      } catch (err) {
        console.error("Error fetching timetable:", err);
      }
      setLoading(false);
    };

    fetchTimetable();
  }, [selectedDay]);

  // Handle day change
  const handleDayChange = (event, newValue) => {
    setSelectedDay(newValue);
  };

  // Get period details for a specific class and time slot
  const getPeriodDetails = (timetable, timeSlot) => {
    const period = timetable.periods.find(
      (p) =>
        p.startTime === timeSlot.startTime && p.endTime === timeSlot.endTime
    );
    return period || null;
  };

  useEffect(() => {
    setHeaderSelectors({
      classSelector: false,
      sectionSelector: false,
      subjectSelector: false,
      dateSelector: true,
      backMethod: null,
    });
  }, []);

  return (
    <Box sx={{ width: "100%", p: 2 }}>
      <Tabs
        value={selectedDay}
        onChange={handleDayChange}
        variant="scrollable"
        scrollButtons="auto"
        sx={{ mb: 2 }}
      >
        {daysOfWeek.map((day) => (
          <Tab key={day} label={day} value={day} />
        ))}
      </Tabs>

      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
          <CircularProgress />
        </Box>
      ) : timetableData.length > 0 ? (
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}
                >
                  Class
                </TableCell>
                {timeSlots.map((slot, index) => (
                  <TableCell
                    key={index}
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      backgroundColor: "#f5f5f5",
                      minWidth: 200,
                    }}
                  >
                    {`${slot.startTime} - ${slot.endTime}`}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {timetableData.map((timetable) => (
                <TableRow key={timetable._id}>
                  <TableCell sx={{ fontWeight: "bold", whiteSpace: "nowrap" }}>
                    {`${timetable.className} - ${timetable.section}`}
                  </TableCell>
                  {timeSlots.map((slot, index) => {
                    const period = getPeriodDetails(timetable, slot);
                    return (
                      <TableCell
                        key={index}
                        sx={{
                          backgroundColor: period ? "#f8f9fa" : "inherit",
                          border: "1px solid #dee2e6",
                        }}
                      >
                        {period ? (
                          <Box>
                            <Typography
                              variant="subtitle2"
                              sx={{ fontWeight: "bold" }}
                            >
                              {period.subject}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              {teacherNames[period.teacher] ||
                                "Unknown Teacher"}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              Room: {period.room}
                            </Typography>
                          </Box>
                        ) : null}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="h6" sx={{ textAlign: "center", my: 3 }}>
          No timetable data available for {selectedDay}.
        </Typography>
      )}
    </Box>
  );
};

export default ShowTimeTable;

import React, { useState, useEffect, useMemo } from "react";

import baseUrl from "./Configs";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useSetRecoilState, useRecoilState } from "recoil";
import {
  teacherSelectedSectionState,
  teacherSelectedClassState,
  teacherSelectedDateState,
  teacherSelectedSubjectState,
  selectedClassIdState,
  receivedClassListState,
  headerSelectorsState,
  // selectedMonthIndexState,
  // selectedFeeTypeState
} from "./Atoms";

import "./Css/Header.css";

const Header = ({ showSelectors }) => {
  const [classes, setClasses] = useState([]);
  const [students, setStudents] = useState([]);

  const setReceivedClassList = useSetRecoilState(receivedClassListState);

  const [selectedClass, setSelectedClass] = useRecoilState(
    teacherSelectedClassState
  );
  const [selectedSection, setSelectedSection] = useRecoilState(
    teacherSelectedSectionState
  );
  const [selectedSubject, setSelectedSubject] = useRecoilState(
    teacherSelectedSubjectState
  );
  // const [monthIndex, setMonthIndex] = useRecoilState(selectedMonthIndexState);
  // const [feeType, setFeeType] = useRecoilState(selectedFeeTypeState);
  const [selectedDate, setTeacherSelectedDate] = useRecoilState(
    teacherSelectedDateState
  );
  const [selectedClassId, setSelectedClassId] =
    useRecoilState(selectedClassIdState);

  const [headerSelectors, setHeaderSelectors] =
    useRecoilState(headerSelectorsState);

  const imgSrc = `././Assets/chequeredFlag.png`;
  const getClassesUrl = `${baseUrl}/v1/classroute/getavailableclasses`;
  const getStudentsUrl = `${baseUrl}/v1/classroute/getstudentlist?className=${selectedClass}&section=${selectedSection}`;

  const handleDateChange = (e) => {
    setTeacherSelectedDate(new Date(e));
    console.log(selectedDate);
  };
  const fetchClasses = async () => {
    const response = await fetch(getClassesUrl, optionsForGet);
    const data = await response.json();
    setClasses(groupClasses(data));
    setReceivedClassList(data);
  };

  const optionsForGet = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const groupClasses = (classes) => {
    return classes.reduce((groupedClasses, cls) => {
      // Find the class in the grouped classes
      const classIndex = groupedClasses.findIndex(
        (groupedClass) => groupedClass.className === cls.className
      );

      // If the class is not yet in the grouped classes, add it
      if (classIndex === -1) {
        groupedClasses.push({
          className: cls.className,
          sectionsAndSubjects: [
            {
              section: cls.section,
              subjects: cls.subjects,
              classId: cls.classId,
            },
          ],
        });
      } else {
        // Otherwise, add the section and subjects to the class in the grouped classes
        groupedClasses[classIndex].sectionsAndSubjects.push({
          section: cls.section,
          classId: cls.classId,
          subjects: cls.subjects,
        });
      }
      return groupedClasses;
    }, []);
  };

  useEffect(() => {
    fetchClasses();
  }, []);

  useEffect(() => {
    // Update selectedClassId whenever class or section changes

    // Find the selected class object by className
    const selectedClassObj = classes.find(
      (cls) => cls.className == selectedClass
    );

    if (selectedClassObj) {
      // Find the selected section object within the selected class
      const selectedSectionObj = selectedClassObj.sectionsAndSubjects.find(
        (sectionObj) => sectionObj.section == selectedSection
      );

      if (selectedSectionObj) {
        setSelectedClassId(selectedSectionObj.classId); // Set classId from the selected section
      } else {
        setSelectedClassId(""); // Reset if no matching section is found
      }
    } else {
      setSelectedClassId(""); // Reset if no matching class is found
    }

    // console.log("Selected Class ID: ", selectedClassId);
  }, [selectedClass, selectedSection, classes]);

  let selectedClassObj = classes.find((cls) => cls.className === selectedClass);
  let selectedSectionObj =
    selectedClassObj &&
    selectedClassObj.sectionsAndSubjects.find(
      (sAs) => sAs.section == selectedSection
    );

  return (
    <div className="header-container">
      <div className="header-logo">
        {/* {selectors.backButton && ( */}
        <div className="header-back_button">
          <IconButton
            onClick={() =>
              headerSelectors.backMethod
                ? headerSelectors.backMethod()
                : window.history.back()
            }
          >
            <ArrowBackIcon sx={{ color: "white" }} />
          </IconButton>
        </div>
        <h3>SpellStudy</h3>
      </div>
      <div className="header-selector">
        {headerSelectors?.classSelector && (
          <FormControl fullWidth style={{ minWidth: "130px" }}>
            <InputLabel id="class-select-label">Select Class</InputLabel>
            <Select
              labelId="class-select-label"
              label="Select Class"
              id="class-select"
              value={selectedClass}
              onChange={(e) => setSelectedClass(e.target.value)}
            >
              {classes.map((cls, index) => (
                <MenuItem key={index} value={cls.className}>
                  {cls.className}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {headerSelectors?.sectionSelector && (
          <FormControl fullWidth style={{ minWidth: "130px" }}>
            <InputLabel id="section-select-label">Select Section</InputLabel>
            <Select
              labelId="section-select-label"
              label="Select Section"
              id="section-select"
              value={selectedSection}
              onChange={(e) => setSelectedSection(e.target.value)}
            >
              {selectedClassObj &&
                selectedClassObj.sectionsAndSubjects.map((sAs, index) => (
                  <MenuItem key={index} value={sAs.section}>
                    {sAs.section}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
        {headerSelectors?.subjectSelector && (
          <FormControl fullWidth style={{ minWidth: "130px" }}>
            <InputLabel id="subject-select-label">Select Subject</InputLabel>
            <Select
              labelId="subject-select-label"
              label="Select Subject"
              id="subject-select"
              value={selectedSubject}
              onChange={(e) => setSelectedSubject(e.target.value)}
            >
              {selectedSectionObj &&
                selectedSectionObj.subjects.map((subject, index) => (
                  <MenuItem key={index} value={subject.subjectName}>
                    {subject.subjectName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
        {headerSelectors?.dateSelector && (
          <FormControl fullWidth style={{ minWidth: "130px" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Date"
                inputFormat="dd/MM/yyyy"
                value={selectedDate}
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </FormControl>
        )}
      </div>
    </div>
  );
};

export default Header;

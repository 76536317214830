import React, { useState, useEffect } from "react";
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import { makeStyles } from "@mui/styles";

import {
  receivedStudentListState,
  studentwiseDailyReportState,
  teacherSelectedClassState,
  teacherSelectedSectionState,
  teacherSelectedDateState,
} from "../Atoms";
import baseUrl from "../Configs";
import "./Css/DayRating.css";

import Slider from "@mui/material/Slider";

const useStyles = makeStyles({
  red: {
    height: 2, // Change this to increase the height of the slider
    color: "#ff0000", // Change this to the color you want
    touchAction: "pan-y",
    padding: "0px",
  },
  blue: {
    touchAction: "pan-y",
    padding: "0px",
  },
  dummy: {
    width: "100%",
    padding: "0px",
  },
});

const DayRating = () => {
  // const receivedStudentList = useRecoilValue(receivedStudentListState);
  const setStudentwiseDailyReportState = useSetRecoilState(
    studentwiseDailyReportState
  );
  const [selectedClass, setSelectedClass] = useRecoilState(
    teacherSelectedClassState
  );
  const [selectedSection, setSelectedSection] = useRecoilState(
    teacherSelectedSectionState
  );
  const [receivedStudentList, setReceivedStudentListState] = useRecoilState(
    receivedStudentListState
  );
  const date = useRecoilValue(teacherSelectedDateState);

  const [scores, setScores] = useState({});

  const classes = useStyles();

  const fetchStudents = async () => {
    const getStudentsUrl = `${baseUrl}/v1/classroute/getstudentlist?className=${selectedClass}&section=${selectedSection}`;
    const response = await fetch(getStudentsUrl, optionsForGet);
    const data = await response.json();
    setReceivedStudentListState(data);
  };

  const optionsForGet = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const updateStudentwiseScore = (studentId, type, score) => {
    setScores((prevScores) => ({
      ...prevScores,
      [studentId]: {
        ...prevScores[studentId],
        rollNumber: studentId,
        [type]: score,
      },
    }));
  };

  useEffect(() => {
    setStudentwiseDailyReportState(Object.values(scores));
  }, [scores, setStudentwiseDailyReportState]);

  useEffect(() => {
    if (receivedStudentList.length === 0) {
      fetchStudents();
    }
  }, [selectedClass, selectedSection]);

  return (
    <div className="day-rating">
      <div className="day-rating_header">
        {/* indicator to show first slider(blue) as Classwork and second slider(red) as Homework */}
        <div className="day-rating_header-legend">
          Class Score
          <Slider
            className={classes.dummy}
            aria-label="Class Score"
            defaultValue={2}
            valueLabelDisplay="auto"
            step={1}
            marks
            min={0}
            max={3}
          />
        </div>
        <div className="day-rating_header-legend">
          Homework Score
          <Slider
            className={classes.dummy + " " + classes.red}
            aria-label="Class Score"
            defaultValue={2}
            valueLabelDisplay="auto"
            step={1}
            marks
            min={0}
            max={3}
          />
        </div>
      </div>
      <ul className="day-rating_student-list-root">
        {receivedStudentList &&
          receivedStudentList.map((student) => {
            return (
              <li id="day-rating_student-list_item" key={student.rollNumber}>
                <div id="day-rating_student-list_item-header">
                  <span>{student.rollNumber}</span>
                  <span>{student.name}</span>
                </div>
                <div className="day-rating_slider-container">
                  <Slider
                    className={classes.blue}
                    aria-label="Class Score"
                    defaultValue={0}
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={0}
                    max={10}
                    onChange={(e) =>
                      updateStudentwiseScore(
                        student.rollNumber,
                        "classScore",
                        e.target.value
                      )
                    }
                  />
                  <Slider
                    className={classes.red}
                    aria-label="Homework Score"
                    defaultValue={0}
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={0}
                    max={10}
                    onChange={(e) =>
                      updateStudentwiseScore(
                        student.rollNumber,
                        "homeworkScore",
                        e.target.value
                      )
                    }
                  />
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default DayRating;

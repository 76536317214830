import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import BounceLoader from "react-spinners/ClipLoader";
import baseUrl from "./Configs";
import {
  TextField,
  Button,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Box,
  Grid,
  Typography,
  IconButton,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const AddStudent = ({ onSubmit }) => {
  const [isLoading, setIsLoading] = useState(false);
  // Student Info
  const [name, setName] = useState("");
  const [rollNumber, setRollNumber] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("");
  const [admissionNumber, setAdmissionNumber] = useState("");
  const [previousSchool, setPreviousSchool] = useState("");
  const [busService, setBusService] = useState("");
  const [busNumber, setBusNumber] = useState("");
  const [busStop, setBusStop] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  // Guardian Info (each with password)
  const [parentsInfo, setParentsInfo] = useState([
    {
      relation: "",
      name: "",
      email: "",
      phoneNumber: "",
      address: "",
      education: "",
      occupation: "",
      password: "",
    },
  ]);

  // Dialog states for error and success
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorDialogMessage, setErrorDialogMessage] = useState("");
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [successDialogMessage, setSuccessDialogMessage] = useState("");

  const navigate = useNavigate();

  // Refs for required fields
  const nameRef = useRef(null);
  const classRef = useRef(null);
  const sectionRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  const getClassesUrl = `${baseUrl}/v1/classroute/getavailableclasses`;

  const optionsForClass = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const handleClassChange = (event) => {
    setSelectedClass(event.target.value);
    setSelectedSection(""); // Reset section when class changes
  };

  const handleSectionChange = (event) => {
    setSelectedSection(event.target.value);
  };

  const handleParentChange = (index, field, value) => {
    const updatedParents = [...parentsInfo];
    updatedParents[index][field] = value;
    setParentsInfo(updatedParents);
  };

  const addParent = () => {
    setParentsInfo([
      ...parentsInfo,
      {
        relation: "",
        name: "",
        email: "",
        phoneNumber: "",
        address: "",
        education: "",
        occupation: "",
        password: "",
      },
    ]);
  };

  const removeParent = (index) => {
    const updatedParents = parentsInfo.filter((_, i) => i !== index);
    setParentsInfo(updatedParents);
  };

  const StudentDetails = {
    name,
    className: selectedClass,
    section: selectedSection,
    rollNumber,
    address,
    parentsInfo,
    admissionNumber,
    previousSchool,
    busService,
    busNumber,
    busStop,
    gender,
    email,
    dateOfBirth,
    phoneNumber,
    username,
    password,
  };

  const addNewStudentUrl = `${baseUrl}/v1/studentroute/addnewstudent`;

  const options = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    body: JSON.stringify(StudentDetails),
  };

  // Download the return data as a text file.
  const downloadReturnData = (data) => {
    const blob = new Blob([JSON.stringify(data, null, 2)], {
      type: "text/plain;charset=utf-8",
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    console.log(data);
    // Sanitize the student's name by replacing spaces with underscores.
    const studentName = data.newStudent.name || "";
    const sanitizedName = studentName.trim().replace(/\s+/g, "_");
    const className = data.newStudent.className || "";
    const section = data.newStudent.section || "";

    link.href = url;
    link.download = `${sanitizedName}_${className}_${section}.txt`;
    link.click();
    window.URL.revokeObjectURL(url);
  };

  const validateForm = () => {
    // Validate student required fields:
    if (!name.trim()) {
      setErrorDialogMessage("Please fill in Full Name.");
      setErrorDialogOpen(true);
      nameRef.current && nameRef.current.focus();
      return false;
    }
    if (!selectedClass) {
      setErrorDialogMessage("Please select a class.");
      setErrorDialogOpen(true);
      classRef.current && classRef.current.focus();
      return false;
    }
    if (!selectedSection) {
      setErrorDialogMessage("Please select a section.");
      setErrorDialogOpen(true);
      sectionRef.current && sectionRef.current.focus();
      return false;
    }
    if (!email.trim()) {
      setErrorDialogMessage("Please enter Email/Username.");
      setErrorDialogOpen(true);
      emailRef.current && emailRef.current.focus();
      return false;
    }
    if (!password.trim()) {
      setErrorDialogMessage("Please enter Password.");
      setErrorDialogOpen(true);
      passwordRef.current && passwordRef.current.focus();
      return false;
    }
    // Validate parent's info: at least one parent must have non-empty email or phoneNumber.
    const isParentContactFilled = parentsInfo.some(
      (p) =>
        (p.email && p.email.trim() !== "") ||
        (p.phoneNumber && p.phoneNumber.trim() !== "")
    );
    if (!isParentContactFilled) {
      setErrorDialogMessage(
        "At least one parent must have either Email or Phone Number."
      );
      setErrorDialogOpen(true);
      return false;
    }
    // Also, ensure every parent's name and relation are provided.
    for (let i = 0; i < parentsInfo.length; i++) {
      const parent = parentsInfo[i];
      if (!parent.name.trim() || !parent.relation.trim()) {
        setErrorDialogMessage("Each parent's Name and Relation are required.");
        setErrorDialogOpen(true);
        return false;
      }
    }
    return true;
  };

  const addNewStudent = async () => {
    if (!validateForm()) return;

    setIsLoading(true);
    try {
      const response = await fetch(addNewStudentUrl, options);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const jsonData = await response.json();
      console.log(jsonData);
      // Show success dialog with return data.
      setSuccessDialogMessage(JSON.stringify(jsonData, null, 2));
      setSuccessDialogOpen(true);
      // Download the return data as a text file.
      downloadReturnData(jsonData.data);
    } catch (e) {
      console.error("Failed to add student:", e);
      setErrorDialogMessage("Failed to add student. Please try again.");
      setErrorDialogOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const groupClasses = (classesData) => {
    return classesData.reduce((groupedClasses, cls) => {
      const classIndex = groupedClasses.findIndex(
        (groupedClass) => groupedClass.className === cls.className
      );
      if (classIndex === -1) {
        groupedClasses.push({
          className: cls.className,
          sectionsAndSubjects: [
            { section: cls.section, subjects: cls.subjects },
          ],
        });
      } else {
        groupedClasses[classIndex].sectionsAndSubjects.push({
          section: cls.section,
          subjects: cls.subjects,
        });
      }
      return groupedClasses;
    }, []);
  };

  useEffect(() => {
    setIsLoading(true);
    fetch(getClassesUrl, optionsForClass)
      .then((response) => response.json())
      .then((data) => {
        setClasses(groupClasses(data));
      })
      .catch((error) => {
        console.error("Failed to fetch classes:", error);
        setErrorDialogMessage("Failed to fetch classes. Please try again.");
        setErrorDialogOpen(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleErrorDialogClose = () => {
    setErrorDialogOpen(false);
  };

  const handleSuccessDialogClose = () => {
    setSuccessDialogOpen(false);
    onSubmit();
  };

  return (
    <Box sx={{ p: 3, position: "relative" }}>
      {isLoading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1,
          }}
        >
          <BounceLoader color="#4A90E2" loading={isLoading} size={60} />
        </Box>
      )}
      <Typography variant="h4" align="center" gutterBottom>
        Add New Student
      </Typography>
      <Grid container spacing={3}>
        {/* Left Column - Student Information */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Student Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth required>
                    <InputLabel id="class-select-label">Class</InputLabel>
                    <Select
                      labelId="class-select-label"
                      id="class-select"
                      value={selectedClass}
                      label="Class"
                      onChange={handleClassChange}
                      inputRef={classRef}
                    >
                      {classes.map((cls, index) => (
                        <MenuItem key={index} value={cls.className}>
                          {`Class ${cls.className}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth required>
                    <InputLabel id="section-select-label">Section</InputLabel>
                    <Select
                      labelId="section-select-label"
                      id="section-select"
                      value={selectedSection}
                      label="Section"
                      onChange={handleSectionChange}
                      disabled={!selectedClass}
                      inputRef={sectionRef}
                    >
                      {selectedClass &&
                        classes
                          .find((cls) => cls.className === selectedClass)
                          ?.sectionsAndSubjects.map((sAs, index) => (
                            <MenuItem key={index} value={sAs.section}>
                              {sAs.section}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Full Name"
                    variant="outlined"
                    fullWidth
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    inputRef={nameRef}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth required>
                    <InputLabel id="gender-select-label">Gender</InputLabel>
                    <Select
                      labelId="gender-select-label"
                      id="gender-select"
                      value={gender}
                      label="Gender"
                      onChange={(e) => setGender(e.target.value)}
                    >
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Admission Number"
                    variant="outlined"
                    fullWidth
                    value={admissionNumber}
                    onChange={(e) => setAdmissionNumber(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Previous School"
                    variant="outlined"
                    fullWidth
                    value={previousSchool}
                    onChange={(e) => setPreviousSchool(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Bus Service"
                    variant="outlined"
                    fullWidth
                    value={busService}
                    onChange={(e) => setBusService(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Bus Number"
                    variant="outlined"
                    fullWidth
                    value={busNumber}
                    onChange={(e) => setBusNumber(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Bus Stop"
                    variant="outlined"
                    fullWidth
                    value={busStop}
                    onChange={(e) => setBusStop(e.target.value)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Grid>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Student's Login Information
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label="Email/Username"
                      variant="outlined"
                      fullWidth
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      inputRef={emailRef}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Password"
                      variant="outlined"
                      type="password"
                      fullWidth
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      inputRef={passwordRef}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* Right Column - Contact Information and Guardians */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Contact Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Date of Birth"
                      value={dateOfBirth}
                      onChange={(newValue) => setDateOfBirth(newValue)}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Address"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={3}
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Roll Number"
                    variant="outlined"
                    fullWidth
                    value={rollNumber}
                    onChange={(e) => setRollNumber(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Phone Number"
                    variant="outlined"
                    fullWidth
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {/* Guardians Section */}
          <Box mt={4}>
            <Typography variant="h6" gutterBottom>
              Guardian's Information
            </Typography>
            {parentsInfo.map((parent, index) => (
              <Card key={index} sx={{ mb: 2 }}>
                <CardContent>
                  <Box sx={{ position: "relative" }}>
                    <IconButton
                      aria-label="remove guardian"
                      onClick={() => removeParent(index)}
                      disabled={parentsInfo.length === 1}
                      sx={{ position: "absolute", top: 8, right: 8 }}
                    >
                      <RemoveCircleOutlineIcon color="error" />
                    </IconButton>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth required>
                          <InputLabel id={`relation-label-${index}`}>
                            Relation
                          </InputLabel>
                          <Select
                            labelId={`relation-label-${index}`}
                            id={`relation-select-${index}`}
                            value={parent.relation}
                            label="Relation"
                            onChange={(e) =>
                              handleParentChange(
                                index,
                                "relation",
                                e.target.value
                              )
                            }
                          >
                            <MenuItem value="father">Father</MenuItem>
                            <MenuItem value="mother">Mother</MenuItem>
                            <MenuItem value="guardian">Guardian</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Name"
                          variant="outlined"
                          fullWidth
                          required
                          value={parent.name}
                          onChange={(e) =>
                            handleParentChange(index, "name", e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Email/Username"
                          variant="outlined"
                          fullWidth
                          value={parent.email}
                          onChange={(e) =>
                            handleParentChange(index, "email", e.target.value)
                          }
                        />
                      </Grid>
                      {/* New Guardian Password Field */}
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Password"
                          variant="outlined"
                          type="password"
                          fullWidth
                          value={parent.password}
                          onChange={(e) =>
                            handleParentChange(
                              index,
                              "password",
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Phone Number"
                          variant="outlined"
                          fullWidth
                          value={parent.phoneNumber}
                          onChange={(e) =>
                            handleParentChange(
                              index,
                              "phoneNumber",
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Address"
                          variant="outlined"
                          fullWidth
                          multiline
                          rows={2}
                          value={parent.address}
                          onChange={(e) =>
                            handleParentChange(index, "address", e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Education"
                          variant="outlined"
                          fullWidth
                          value={parent.education}
                          onChange={(e) =>
                            handleParentChange(
                              index,
                              "education",
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Occupation"
                          variant="outlined"
                          fullWidth
                          value={parent.occupation}
                          onChange={(e) =>
                            handleParentChange(
                              index,
                              "occupation",
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            ))}
            <Button
              variant="outlined"
              startIcon={<AddCircleOutlineIcon />}
              onClick={addParent}
            >
              Add Guardian
            </Button>
          </Box>
        </Grid>
      </Grid>

      {/* Submit Button */}
      <Box sx={{ mt: 4, textAlign: "center" }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={addNewStudent}
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Add New Student"
          )}
        </Button>
      </Box>

      {/* Error Dialog */}
      <Dialog
        open={errorDialogOpen}
        onClose={handleErrorDialogClose}
        aria-labelledby="error-dialog-title"
      >
        <DialogTitle id="error-dialog-title">Error</DialogTitle>
        <DialogContent>
          <Typography>{errorDialogMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrorDialogClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* Success Dialog */}
      <Dialog
        open={successDialogOpen}
        onClose={handleSuccessDialogClose}
        aria-labelledby="success-dialog-title"
      >
        <DialogTitle id="success-dialog-title">Success</DialogTitle>
        <DialogContent>
          <Typography>Student created successfully. Returned data:</Typography>
          <pre style={{ whiteSpace: "pre-wrap" }}>{successDialogMessage}</pre>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuccessDialogClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AddStudent;

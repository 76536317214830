import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import {
  teacherSelectedClassState,
  teacherSelectedSectionState,
} from "../Atoms";
import baseUrl from "../Configs";

import {
  Box,
  TextField,
  IconButton,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const AddNotification = () => {
  const teacherSelectedClass = useRecoilValue(teacherSelectedClassState);
  const teacherSelectedSection = useRecoilValue(teacherSelectedSectionState);

  const [notificationMessages, setNotificationMessages] = useState([""]);
  const [notices, setNotices] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingNotice, setEditingNotice] = useState(null);

  const fetchNoticesUrl = `${baseUrl}/v1/classroute/getnoticesbyclass?className=${teacherSelectedClass}&section=${teacherSelectedSection}`;
  const addNoticeUrl = `${baseUrl}/v1/classroute/addnewnotice`;
  const deleteNoticeUrl = `${baseUrl}/v1/classroute/deletenotice`;
  const editNoticeUrl = `${baseUrl}/v1/classroute/editnotice`;

  const fetchNotices = async () => {
    try {
      const response = await fetch(fetchNoticesUrl, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      const data = await response.json();

      if (data.success && Array.isArray(data.notices)) {
        setNotices(data.notices); // Set notices if it's an array
      } else {
        setNotices([]); // Default to empty array if no notices or if response is not in expected format
        console.error(
          "Failed to fetch notices:",
          data.message || "Unknown error"
        );
      }
    } catch (error) {
      setNotices([]); // Default to empty array on error
      console.error("Error fetching notices:", error);
    }
  };

  useEffect(() => {
    if (teacherSelectedClass && teacherSelectedSection) {
      fetchNotices();
      console.log("Notices fetched:", notices);
    }
  }, [teacherSelectedClass, teacherSelectedSection]);

  const handleInputChange = (index, event) => {
    const values = [...notificationMessages];
    values[index] = event.target.value;
    setNotificationMessages(values);
  };

  const handleAddClick = () => {
    setNotificationMessages([...notificationMessages, ""]);
  };

  const handleRemoveClick = (index) => {
    const values = [...notificationMessages];
    values.splice(index, 1);
    setNotificationMessages(values);
  };

  const handleSubmit = async () => {
    if (!teacherSelectedClass || !teacherSelectedSection) {
      setOpenDialog(true);
      return;
    }

    const notificationData = {
      className: teacherSelectedClass,
      section: teacherSelectedSection,
      title: notificationMessages[0], // Assuming the first entry is the title
      message: notificationMessages.slice(1).join("\n"), // The rest is the message
    };

    const response = await fetch(addNoticeUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(notificationData),
    });

    if (response.ok) {
      alert("Notification added successfully");
      setNotificationMessages([""]); // Clear the input
      fetchNotices(); // Refresh the notice list
    } else {
      alert("Error adding notification");
    }
  };

  const handleDelete = async (noticeId) => {
    const response = await fetch(
      `${deleteNoticeUrl}?className=${teacherSelectedClass}&section=${teacherSelectedSection}&noticeId=${noticeId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );

    if (response.ok) {
      alert("Notification deleted successfully");
      setNotices(notices.filter((notice) => notice._id !== noticeId));
    } else {
      alert("Error deleting notification");
    }
  };

  const handleEdit = (notice) => {
    setEditingNotice(notice);
    setNotificationMessages([notice.title, notice.message]);
  };

  const handleUpdate = async () => {
    const editData = {
      noticeId: editingNotice._id,
      className: teacherSelectedClass,
      section: teacherSelectedSection,
      title: notificationMessages[0],
      message: notificationMessages.slice(1).join("\n"),
    };

    const response = await fetch(editNoticeUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(editData),
    });

    if (response.ok) {
      alert("Notification updated successfully");
      setEditingNotice(null); // Clear editing state
      setNotificationMessages([""]);
      fetchNotices(); // Refresh the notice list
    } else {
      alert("Error updating notification");
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Manage Notifications
      </Typography>

      {/* Display all existing notices */}
      {notices.map((notice) => (
        <Card key={notice._id} sx={{ marginBottom: 2 }}>
          <CardContent>
            <Typography variant="h5">{notice.title}</Typography>
            <Typography>{notice.message}</Typography>
            <Typography variant="caption">
              Posted by: {notice.postedBy}
            </Typography>
            <Typography variant="caption">
              Date: {new Date(notice.date).toLocaleDateString()}
            </Typography>
          </CardContent>
          <CardActions>
            <IconButton color="primary" onClick={() => handleEdit(notice)}>
              <EditIcon />
            </IconButton>
            <IconButton
              color="secondary"
              onClick={() => handleDelete(notice._id)}
            >
              <DeleteIcon />
            </IconButton>
          </CardActions>
        </Card>
      ))}

      {/* Add/Edit Notification Form */}
      <Typography variant="h6" gutterBottom>
        {editingNotice ? "Edit Notification" : "Add New Notification"}
      </Typography>

      {notificationMessages.map((message, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: 2,
          }}
        >
          <TextField
            fullWidth
            label={index === 0 ? "Notification Title" : `Message Line ${index}`}
            variant="outlined"
            value={message}
            onChange={(event) => handleInputChange(index, event)}
            sx={{ marginRight: 2 }}
          />
          {index === notificationMessages.length - 1 && (
            <IconButton
              color="primary"
              onClick={handleAddClick}
              aria-label="add"
              sx={{ marginRight: 1 }}
            >
              <AddIcon />
            </IconButton>
          )}
          {notificationMessages.length > 1 && (
            <IconButton
              color="secondary"
              onClick={() => handleRemoveClick(index)}
              aria-label="remove"
            >
              <RemoveIcon />
            </IconButton>
          )}
        </Box>
      ))}

      <Button
        variant="contained"
        color="primary"
        onClick={editingNotice ? handleUpdate : handleSubmit}
        sx={{ marginTop: 3 }}
      >
        {editingNotice ? "Update Notification" : "Submit Notification"}
      </Button>

      {/* Dialog for missing fields */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Incomplete Information</DialogTitle>
        <DialogContent>
          <Typography>
            Please select a class and section before submitting the
            notification.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AddNotification;

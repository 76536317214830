import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  MenuItem,
  Select,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import baseUrl from "./Configs";
import Header from "./Header";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  teacherSelectedClassState,
  teacherSelectedSubjectState,
  teacherSelectedSectionState,
  headerSelectorsState,
} from "./Atoms";

const Topic = ({
  topic,
  onChange,
  onAddSubtopic,
  onDeleteTopic,
  path = [],
  level = 0,
}) => (
  <Card style={{ marginLeft: `${level * 20}px`, marginTop: "10px" }}>
    <CardContent>
      <Typography variant="h6">
        {level === 0 ? "Main Topic" : "Subtopic"}
      </Typography>
      <TextField
        label="Topic Title"
        name="title"
        value={topic.title}
        onChange={(e) => onChange(e, [...path, "title"])}
      />
      <TextField
        label="Topic Description"
        name="description"
        value={topic.description}
        onChange={(e) => onChange(e, [...path, "description"])}
      />
      {topic.subtopics.map((subtopic, index) => (
        <Topic
          key={index}
          topic={subtopic}
          onChange={onChange}
          onAddSubtopic={onAddSubtopic}
          onDeleteTopic={onDeleteTopic} // pass onDeleteTopic prop here
          path={[...path, "subtopics", index]}
          level={level + 1}
        />
      ))}
      <Button onClick={() => onAddSubtopic([...path, "subtopics"])}>
        Add Subtopic
      </Button>
      <Button onClick={() => onDeleteTopic(path)}>Delete Topic</Button>
    </CardContent>
  </Card>
);

const AddNewSyllabus = (props) => {
  const [selectedBoard, setSelectedBoard] = useState("");
  const [boardDialogOpen, setBoardDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const teacherSelectedClass = useRecoilValue(teacherSelectedClassState);
  const teacherSelectedSection = useRecoilValue(teacherSelectedSectionState);
  const teacherSelectedSubject = useRecoilValue(teacherSelectedSubjectState);
  const [isSyllabusFound, setIsSyllabusFound] = useState(false); // Track if syllabus is found
  const [headerSelectors, setHeaderSelectors] =
    useRecoilState(headerSelectorsState);
  const [openDialog, setOpenDialog] = useState(false); // Manage dialog visibility
  const [dialogMessage, setDialogMessage] = useState(""); // Dialog message

  let editSyllabusUrl = `${baseUrl}/v1/syllabusroute/updatesyllabus`;
  let addSyllabusFromDefaultUrl = `${baseUrl}/v1/syllabusroute/createnewsyllabusfrommaster`;
  let resetSyllabusUrl = `${baseUrl}/v1/syllabusroute/resetsyllabusfrommaster`;

  // Initial empty syllabus state
  const [syllabus, setSyllabus] = useState({
    className: teacherSelectedClass,
    section: teacherSelectedSection,
    subject: teacherSelectedSubject,
    syllabus: {
      topics: [
        {
          title: "",
          description: "",
          subtopics: [],
        },
      ],
    },
  });

  const handleInputChange = (event, path) => {
    const { name, value } = event.target;
    const newSyllabus = { ...syllabus };
    if (path.length === 1) {
      newSyllabus[path[0]] = value;
    } else {
      let currentLevel = newSyllabus;
      for (let i = 0; i < path.length - 1; i++) {
        currentLevel = currentLevel[path[i]];
      }
      currentLevel[path[path.length - 1]] = value;
    }
    setSyllabus(newSyllabus);
  };

  const handleAddTopic = () => {
    const newSyllabus = { ...syllabus };
    newSyllabus.syllabus.topics.push({
      title: "",
      description: "",
      subtopics: [],
    });
    setSyllabus(newSyllabus);
  };

  const handleDeleteTopic = (path) => {
    const newSyllabus = { ...syllabus };
    let currentLevel = newSyllabus;
    for (let i = 0; i < path.length - 2; i++) {
      currentLevel = currentLevel[path[i]];
    }
    currentLevel[path[path.length - 2]].splice(path[path.length - 1], 1);
    setSyllabus(newSyllabus);
  };

  const handleAddSubtopic = (path) => {
    const newSyllabus = { ...syllabus };
    let currentLevel = newSyllabus;
    for (let i = 0; i < path.length - 1; i++) {
      currentLevel = currentLevel[path[i]];
    }
    currentLevel[path[path.length - 1]].push({
      title: "",
      description: "",
      subtopics: [],
    });
    setSyllabus(newSyllabus);
  };

  const handleSubmit = async () => {
    if (
      !teacherSelectedClass ||
      !teacherSelectedSection ||
      !teacherSelectedSubject
    ) {
      setDialogMessage(
        "Please select class, section, and subject before submitting the syllabus."
      );
      setOpenDialog(true);
      return;
    }

    try {
      let response;
      if (isSyllabusFound) {
        response = await axios.put(editSyllabusUrl, syllabus, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
      } else {
        response = await axios.post(
          `${baseUrl}/v1/syllabusroute/addnewsyllabus`,
          syllabus,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      }
      props.onSubmit();
    } catch (error) {
      console.error(error);
    }
  };

  const handleSetFromDefault = async () => {
    try {
      const response = await fetch(addSyllabusFromDefaultUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          className: teacherSelectedClass,
          section: teacherSelectedSection,
          subject: teacherSelectedSubject,
          edBoard: selectedBoard,
        }),
      });
      if (response.ok) {
        props.onSubmit();
      } else {
        console.error("Failed to add syllabus from default");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleConfirmBoardSelection = async () => {
    if (!selectedBoard) {
      setDialogMessage("Please select an education board.");
      setOpenDialog(true);
      return;
    }

    try {
      setIsLoading(true);
      let response;
      if (isSyllabusFound) {
        response = await axios.put(
          resetSyllabusUrl,
          {
            className: teacherSelectedClass,
            section: teacherSelectedSection,
            subject: teacherSelectedSubject,
            edBoard: selectedBoard,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      } else {
        response = await axios.post(
          addSyllabusFromDefaultUrl,
          {
            className: teacherSelectedClass,
            section: teacherSelectedSection,
            subject: teacherSelectedSubject,
            edBoard: selectedBoard,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      }

      if (response.status === 201) {
        await fetchSyllabus(); // Refresh syllabus data
        // props.onSubmit(); // Notify parent component if needed
      }
    } catch (error) {
      console.error(error);
      setDialogMessage("An error occurred. Please try again.");
      setOpenDialog(true);
    } finally {
      setIsLoading(false);
      setBoardDialogOpen(false);
    }
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const fetchSyllabus = async () => {
    if (
      !teacherSelectedClass ||
      !teacherSelectedSection ||
      !teacherSelectedSubject
    )
      return;

    const getSyllabusUrl = `${baseUrl}/v1/syllabusroute/getsyllabus?className=${teacherSelectedClass}&subject=${teacherSelectedSubject}&section=${teacherSelectedSection}`;

    try {
      const response = await axios.get(getSyllabusUrl, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.data.syllabus) {
        setIsSyllabusFound(true);
        setSyllabus({
          className: teacherSelectedClass,
          section: teacherSelectedSection,
          subject: teacherSelectedSubject,
          syllabus: response.data.syllabus,
        });
      } else {
        setIsSyllabusFound(false);
        setSyllabus({
          className: teacherSelectedClass,
          section: teacherSelectedSection,
          subject: teacherSelectedSubject,
          syllabus: { topics: [{ title: "", description: "", subtopics: [] }] },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchSyllabus();
  }, [teacherSelectedClass, teacherSelectedSection, teacherSelectedSubject]);

  useEffect(() => {
    setSyllabus((prevSyllabus) => ({
      ...prevSyllabus,
      className: teacherSelectedClass,
      section: teacherSelectedSection,
      subject: teacherSelectedSubject,
    }));
  }, [teacherSelectedClass, teacherSelectedSection, teacherSelectedSubject]);

  // Set header selectors to show class and section selectors
  useEffect(() => {
    setTimeout(() => {
      setHeaderSelectors({
        classSelector: true,
        sectionSelector: true,
        subjectSelector: true,
        dateSelector: false,
        backMethod: props.handleBackClick,
      });
    }, 10);
  }, []);

  return (
    <div>
      <Header
        selectors={{
          classSelector: true,
          sectionSelector: true,
          subjectSelector: true,
        }}
      />

      {syllabus.syllabus &&
        syllabus.syllabus.topics.length > 0 &&
        syllabus.syllabus.topics.map((topic, index) => (
          <Topic
            key={index}
            topic={topic}
            onChange={handleInputChange}
            onAddSubtopic={handleAddSubtopic}
            onDeleteTopic={handleDeleteTopic}
            path={["syllabus", "topics", index]}
            level={0}
          />
        ))}

      <Button onClick={handleAddTopic} style={{ marginTop: "10px" }}>
        Add Main Topic
      </Button>
      <Grid container spacing={2} style={{ marginTop: "10px" }}>
        <Grid item xs={12} md={6}>
          <Button
            variant="contained"
            fullWidth
            color="primary"
            onClick={handleSubmit}
          >
            {isSyllabusFound ? "Update Syllabus" : "Add Syllabus"}
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            variant="contained"
            fullWidth
            color="primary"
            onClick={() => setBoardDialogOpen(true)}
          >
            {isSyllabusFound ? "Reset to default" : "Add Syllabus from default"}
          </Button>
        </Grid>
      </Grid>

      {/* Board Selection Dialog */}
      <Dialog
        open={boardDialogOpen}
        onClose={() => !isLoading && setBoardDialogOpen(false)}
      >
        <DialogTitle>Select Education Board</DialogTitle>
        <DialogContent>
          <Select
            value={selectedBoard}
            onChange={(e) => setSelectedBoard(e.target.value)}
            fullWidth
            disabled={isLoading}
          >
            <MenuItem value="CBSE">CBSE</MenuItem>
            <MenuItem value="CGBSE">CGBSE</MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          {isLoading ? (
            <CircularProgress size={24} style={{ margin: "0 auto" }} />
          ) : (
            <>
              <Button onClick={() => setBoardDialogOpen(false)}>Cancel</Button>
              <Button onClick={handleConfirmBoardSelection} color="primary">
                Confirm
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      {/* Dialog for missing class, section, or subject */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Incomplete Information</DialogTitle>
        <DialogContent>
          <Typography>{dialogMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddNewSyllabus;

import React, { useState, useRef } from "react";
import { useRecoilValue } from "recoil";
import {
  teacherSelectedClassState,
  teacherSelectedDateState,
  teacherSelectedSubjectState,
  teacherSelectedSectionState,
} from "../Atoms";
import baseUrl from "../Configs";

import {
  Box,
  TextField,
  IconButton,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Stack,
  LinearProgress,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from '@mui/icons-material/Refresh';

const MAX_PHOTOS_PER_POINT = 5;

const AddHomework = ({ onSubmit }) => {
  const teacherSelectedClass = useRecoilValue(teacherSelectedClassState);
  const teacherSelectedSection = useRecoilValue(teacherSelectedSectionState);
  const teacherSelectedDate = useRecoilValue(teacherSelectedDateState);
  const teacherSelectedSubject = useRecoilValue(teacherSelectedSubjectState);

  const [homeworkPoints, setHomeworkPoints] = useState([{ 
    text: "", 
    photos: [], 
    caption: "" 
  }]);
  const [openDialog, setOpenDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cameraDialogOpen, setCameraDialogOpen] = useState(false);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(null);
  const [currentPointIndex, setCurrentPointIndex] = useState(null);
  const [cameraError, setCameraError] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [uploadingFileName, setUploadingFileName] = useState("");
  const videoRef = useRef(null);
  const streamRef = useRef(null);
  const [uploadingPhotos, setUploadingPhotos] = useState({});
  const [uploadErrors, setUploadErrors] = useState({});
  const [completedUploads, setCompletedUploads] = useState({});

  const addHomeworkUrl = `${baseUrl}/v1/classroute/addnewhomework`;
  const getUploadUrlEndpoint = `${baseUrl}/v1/classroute/get-upload-url`;

  const handleInputChange = (pointIndex, event) => {
    const values = [...homeworkPoints];
    values[pointIndex] = { ...values[pointIndex], text: event.target.value };
    setHomeworkPoints(values);
  };

  const handleCaptionChange = (pointIndex, event) => {
    const values = [...homeworkPoints];
    values[pointIndex] = { ...values[pointIndex], caption: event.target.value };
    setHomeworkPoints(values);
  };

  const handlePhotoChange = async (pointIndex, event) => {
    const files = Array.from(event.target.files);
    if (files.length > 0) {
      const values = [...homeworkPoints];
      const currentPhotoCount = values[pointIndex].photos.length;
      
      if (currentPhotoCount + files.length > MAX_PHOTOS_PER_POINT) {
        alert(`You can only add up to ${MAX_PHOTOS_PER_POINT} photos per homework point.`);
        return;
      }

      values[pointIndex] = { 
        ...values[pointIndex], 
        photos: [...values[pointIndex].photos, ...files.map(file => ({ 
          file, 
          fileName: "", 
          uploadStatus: 'pending',
          error: null
        }))]
      };
      setHomeworkPoints(values);

      for (let i = 0; i < files.length; i++) {
        const photoIndex = currentPhotoCount + i;
        try {
          const fileName = await uploadPhotoToB2(files[i], pointIndex, photoIndex);
          
          // Get temporary URL for the uploaded photo
          const urlResponse = await fetch(`${baseUrl}/v1/classroute/get-homework-photo-url`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify({ fileName }),
          });

          if (!urlResponse.ok) {
            throw new Error(`Failed to get photo URL: ${urlResponse.status}`);
          }

          const { downloadUrl } = await urlResponse.json();
          
          setHomeworkPoints(prev => {
            const newState = [...prev];
            newState[pointIndex].photos[photoIndex] = {
              ...newState[pointIndex].photos[photoIndex],
              fileName,
              url: downloadUrl,
              uploadStatus: 'completed'
            };
            return newState;
          });
        } catch (error) {
          console.error(`Error uploading photo ${i + 1}:`, error);
          setHomeworkPoints(prev => {
            const newState = [...prev];
            newState[pointIndex].photos[photoIndex] = {
              ...newState[pointIndex].photos[photoIndex],
              uploadStatus: 'error',
              error: error.message
            };
            return newState;
          });
        }
      }
    }
  };

  const handleAddClick = () => {
    setHomeworkPoints([...homeworkPoints, { text: "", photos: [], caption: "" }]);
  };

  const handleRemoveClick = (pointIndex) => {
    const values = [...homeworkPoints];
    values.splice(pointIndex, 1);
    setHomeworkPoints(values);
  };

  const handleRemovePhoto = (pointIndex, photoIndex) => {
    const values = [...homeworkPoints];
    values[pointIndex].photos.splice(photoIndex, 1);
    setHomeworkPoints(values);
  };

  const uploadPhotoToB2 = async (file, pointIndex, photoIndex) => {
    try {
      console.log('Starting B2 upload process...');
      
      const formData = new FormData();
      formData.append('file', file);
      
      setUploadingPhotos(prev => ({
        ...prev,
        [`${pointIndex}-${photoIndex}`]: 0
      }));

      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        
        xhr.upload.addEventListener('progress', (event) => {
          if (event.lengthComputable) {
            const progress = Math.round((event.loaded / event.total) * 100);
            setUploadingPhotos(prev => ({
              ...prev,
              [`${pointIndex}-${photoIndex}`]: progress
            }));
          }
        });

        xhr.addEventListener('load', () => {
          if (xhr.status >= 200 && xhr.status < 300) {
            const result = JSON.parse(xhr.responseText);
            console.log('File uploaded successfully:', result);
            setCompletedUploads(prev => ({
              ...prev,
              [`${pointIndex}-${photoIndex}`]: true
            }));
            resolve(result.fileName);
          } else {
            const errorText = xhr.responseText;
            console.error('Upload response:', xhr);
            console.error('Upload error text:', errorText);
            setUploadErrors(prev => ({
              ...prev,
              [`${pointIndex}-${photoIndex}`]: `Failed to upload file: ${xhr.status} ${xhr.statusText}`
            }));
            reject(new Error(`Failed to upload file: ${xhr.status} ${xhr.statusText}`));
          }
        });

        xhr.addEventListener('error', () => {
          setUploadErrors(prev => ({
            ...prev,
            [`${pointIndex}-${photoIndex}`]: 'Network error occurred during upload'
          }));
          reject(new Error('Network error occurred during upload'));
        });

        xhr.open('POST', `${baseUrl}/v1/classroute/upload-to-b2`);
        xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('token'));
        xhr.send(formData);
      });
    } catch (error) {
      console.error("Error uploading to B2:", error);
      setUploadErrors(prev => ({
        ...prev,
        [`${pointIndex}-${photoIndex}`]: error.message
      }));
      throw error;
    }
  };

  const handleRetryUpload = async (pointIndex, photoIndex) => {
    const photo = homeworkPoints[pointIndex].photos[photoIndex];
    if (!photo || !photo.file) return;

    setUploadErrors(prev => {
      const newState = { ...prev };
      delete newState[`${pointIndex}-${photoIndex}`];
      return newState;
    });

    setHomeworkPoints(prev => {
      const newState = [...prev];
      newState[pointIndex].photos[photoIndex] = {
        ...newState[pointIndex].photos[photoIndex],
        uploadStatus: 'pending',
        error: null
      };
      return newState;
    });

    try {
      const fileName = await uploadPhotoToB2(photo.file, pointIndex, photoIndex);
      
      const urlResponse = await fetch(`${baseUrl}/v1/classroute/get-homework-photo-url`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify({ fileName }),
      });

      if (!urlResponse.ok) {
        throw new Error(`Failed to get photo URL: ${urlResponse.status}`);
      }

      const { downloadUrl } = await urlResponse.json();
      
      setHomeworkPoints(prev => {
        const newState = [...prev];
        newState[pointIndex].photos[photoIndex] = {
          ...newState[pointIndex].photos[photoIndex],
          fileName,
          url: downloadUrl,
          uploadStatus: 'completed'
        };
        return newState;
      });
    } catch (error) {
      console.error('Error retrying upload:', error);
      setHomeworkPoints(prev => {
        const newState = [...prev];
        newState[pointIndex].photos[photoIndex] = {
          ...newState[pointIndex].photos[photoIndex],
          uploadStatus: 'error',
          error: error.message
        };
        return newState;
      });
    }
  };

  const handleSubmit = async () => {
    if (
      !teacherSelectedClass ||
      !teacherSelectedSection ||
      !teacherSelectedSubject
    ) {
      setOpenDialog(true);
      return;
    }

    // Check if there are any photos in pending state
    const hasPendingPhotos = homeworkPoints.some(point => 
      point.photos.some(photo => photo.uploadStatus === 'pending')
    );
    if (hasPendingPhotos) {
      alert('Please wait for all photos to finish uploading before submitting.');
      return;
    }

    // Check if any photos have errors
    const hasPhotoErrors = Object.keys(uploadErrors).length > 0;
    if (hasPhotoErrors) {
      const shouldProceed = window.confirm('Some photos failed to upload. Do you want to submit the homework without these photos?');
      if (!shouldProceed) {
        return;
      }
    }

    // Check if all completed photos have URLs
    const hasPhotosWithoutUrls = homeworkPoints.some(point => 
      point.photos.some(photo => 
        photo.uploadStatus === 'completed' && (!photo.url || !photo.fileName)
      )
    );
    if (hasPhotosWithoutUrls) {
      alert('Some photos are missing URLs. Please try uploading them again.');
      return;
    }

    setIsSubmitting(true);

    try {
      const homeworkData = {
        className: teacherSelectedClass,
        section: teacherSelectedSection,
        date: teacherSelectedDate,
        homework: {
          subjectName: teacherSelectedSubject,
          works: homeworkPoints.map(point => point.text),
          photos: homeworkPoints.flatMap(point => 
            point.photos
              .filter(photo => photo.uploadStatus === 'completed')
              .map(photo => ({
                fileName: photo.fileName,
                url: photo.url,
                caption: photo.caption || "",
                uploadedAt: new Date().toISOString()
              }))
          )
        }
      };

      console.log('Submitting homework data:', homeworkData);

      const response = await fetch(addHomeworkUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify(homeworkData),
      });

      const responseData = await response.json();
      console.log('Backend response:', responseData);

      if (response.ok) {
        alert("Homework added successfully");
        onSubmit();
      } else {
        // Handle specific error cases
        if (responseData === "Class not found") {
          alert("Error: The selected class and section combination was not found. Please check your selection.");
        } else if (responseData === "School not found") {
          alert("Error: School information not found. Please try again or contact support.");
        } else {
          alert(`Error adding homework: ${responseData.message || 'Unknown error occurred'}`);
        }
      }
    } catch (error) {
      console.error("Error submitting homework:", error);
      alert(`Error adding homework: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCameraClick = async (pointIndex) => {
    try {
      if (window.location.protocol !== 'https:' && window.location.hostname !== 'localhost') {
        throw new Error('Camera access requires HTTPS. Please use a secure connection.');
      }

      setCurrentPointIndex(pointIndex);
      setCameraDialogOpen(true);
      await startCamera();
    } catch (error) {
      console.error('Error in handleCameraClick:', error);
      setCameraError(error.message);
      setCameraDialogOpen(true);
    }
  };

  const startCamera = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(device => device.kind === 'videoinput');
      
      if (videoDevices.length === 0) {
        throw new Error('No camera found on this device');
      }

      const constraints = {
        video: {
          facingMode: { ideal: 'environment' },
          width: { ideal: 1920 },
          height: { ideal: 1080 }
        },
        audio: false
      };

      await new Promise(resolve => setTimeout(resolve, 100));

      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      
      if (!stream) {
        throw new Error('Failed to get camera stream');
      }

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        streamRef.current = stream;
        setCameraError(null);
      } else {
        throw new Error('Video element not ready');
      }
    } catch (error) {
      console.error('Error accessing camera:', error);
      let errorMessage = 'Failed to access camera. ';
      
      if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
        errorMessage += 'Please grant camera permissions in your browser settings.';
      } else if (error.name === 'NotFoundError' || error.name === 'DevicesNotFoundError') {
        errorMessage += 'No camera found on this device.';
      } else if (error.name === 'NotReadableError' || error.name === 'TrackStartError') {
        errorMessage += 'Camera is in use by another application.';
      } else if (error.name === 'OverconstrainedError') {
        errorMessage += 'Camera does not support the requested settings.';
      } else if (error.name === 'StreamApiNotSupportedError') {
        errorMessage += 'Your browser does not support camera access.';
      } else {
        errorMessage += error.message || 'Please try again.';
      }
      
      setCameraError(errorMessage);
      stopCamera();
    }
  };

  const stopCamera = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
      streamRef.current = null;
    }
  };

  const handleCapture = async () => {
    try {
      const video = videoRef.current;
      if (!video || !video.videoWidth || !video.videoHeight) {
        throw new Error('Camera not ready. Please try again.');
      }

      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext('2d');
      
      if (!context) {
        throw new Error('Failed to get canvas context');
      }

      context.drawImage(video, 0, 0);
      
      canvas.toBlob(async (blob) => {
        if (blob) {
          const file = new File([blob], `camera-capture-${Date.now()}.jpg`, { type: 'image/jpeg' });
          const values = [...homeworkPoints];
          const photoIndex = values[currentPointIndex].photos.length;
          
          values[currentPointIndex].photos.push({ 
            file, 
            fileName: "", 
            uploadStatus: 'pending',
            error: null
          });
          setHomeworkPoints(values);

          try {
            const fileName = await uploadPhotoToB2(file, currentPointIndex, photoIndex);
            
            const urlResponse = await fetch(`${baseUrl}/v1/classroute/get-homework-photo-url`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
              body: JSON.stringify({ fileName }),
            });

            if (!urlResponse.ok) {
              throw new Error(`Failed to get photo URL: ${urlResponse.status}`);
            }

            const { downloadUrl } = await urlResponse.json();
            
            setHomeworkPoints(prev => {
              const newState = [...prev];
              newState[currentPointIndex].photos[photoIndex] = {
                ...newState[currentPointIndex].photos[photoIndex],
                fileName,
                url: downloadUrl,
                uploadStatus: 'completed'
              };
              return newState;
            });
          } catch (error) {
            console.error('Error uploading captured photo:', error);
            setHomeworkPoints(prev => {
              const newState = [...prev];
              newState[currentPointIndex].photos[photoIndex] = {
                ...newState[currentPointIndex].photos[photoIndex],
                uploadStatus: 'error',
                error: error.message
              };
              return newState;
            });
          }
        } else {
          throw new Error('Failed to create image from camera');
        }
        handleCloseCamera();
      }, 'image/jpeg', 0.8);
    } catch (error) {
      console.error('Error capturing photo:', error);
      alert(`Failed to capture photo: ${error.message}`);
    }
  };

  const handleCloseCamera = () => {
    stopCamera();
    setCameraDialogOpen(false);
    setCurrentPhotoIndex(null);
    setCameraError(null);
  };

  const renderPhotoGallery = (pointIndex, photos) => {
    if (!photos || photos.length === 0) return null;

    return (
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          {photos.map((photo, photoIndex) => (
            <Grid item xs={12} sm={6} md={4} key={photoIndex}>
              <Card>
                <CardMedia
                  component="img"
                  height="200"
                  image={URL.createObjectURL(photo.file)}
                  alt={`Photo ${photoIndex + 1}`}
                />
                <CardContent>
                  <TextField
                    fullWidth
                    label="Photo Caption (optional)"
                    variant="outlined"
                    value={photo.caption || ""}
                    onChange={(event) => {
                      const values = [...homeworkPoints];
                      values[pointIndex].photos[photoIndex].caption = event.target.value;
                      setHomeworkPoints(values);
                    }}
                  />
                  {(uploadingPhotos[`${pointIndex}-${photoIndex}`] !== undefined || 
                    completedUploads[`${pointIndex}-${photoIndex}`]) && (
                    <Box sx={{ mt: 1 }}>
                      <LinearProgress 
                        variant="determinate" 
                        value={completedUploads[`${pointIndex}-${photoIndex}`] ? 100 : 
                               uploadingPhotos[`${pointIndex}-${photoIndex}`]} 
                        color={completedUploads[`${pointIndex}-${photoIndex}`] ? "success" : "primary"}
                      />
                      <Typography variant="body2" color="textSecondary">
                        {completedUploads[`${pointIndex}-${photoIndex}`] ? 
                          "Upload complete" : 
                          `${uploadingPhotos[`${pointIndex}-${photoIndex}`]}% uploaded`}
                      </Typography>
                    </Box>
                  )}
                  {photo.uploadStatus === 'error' && (
                    <Box sx={{ mt: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Typography color="error" variant="body2">
                        {photo.error}
                      </Typography>
                      <IconButton 
                        size="small" 
                        color="error" 
                        onClick={() => handleRetryUpload(pointIndex, photoIndex)}
                      >
                        <RefreshIcon />
                      </IconButton>
                    </Box>
                  )}
                </CardContent>
                <CardActions>
                  <IconButton 
                    color="error" 
                    onClick={() => handleRemovePhoto(pointIndex, photoIndex)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Add Homework
      </Typography>

      {homeworkPoints.map((point, pointIndex) => (
        <Box
          key={pointIndex}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            marginBottom: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <TextField
              fullWidth
              label={`Homework Point ${pointIndex + 1}`}
              variant="outlined"
              value={point.text}
              onChange={(event) => handleInputChange(pointIndex, event)}
              sx={{ marginRight: 2 }}
            />
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id={`photo-upload-${pointIndex}`}
              type="file"
              multiple
              onChange={(event) => handlePhotoChange(pointIndex, event)}
            />
            <label htmlFor={`photo-upload-${pointIndex}`}>
              <IconButton
                color={point.photos.length > 0 ? "success" : "primary"}
                component="span"
                sx={{ marginRight: 1 }}
              >
                <AttachFileIcon />
              </IconButton>
            </label>
            <IconButton
              color="primary"
              onClick={() => handleCameraClick(pointIndex)}
              sx={{ marginRight: 1 }}
            >
              <CameraAltIcon />
            </IconButton>
            <IconButton
              color="primary"
              onClick={handleAddClick}
              aria-label="add"
              sx={{ marginRight: 1 }}
            >
              <AddIcon />
            </IconButton>
            {homeworkPoints.length > 1 && (
              <IconButton
                color="secondary"
                onClick={() => handleRemoveClick(pointIndex)}
                aria-label="remove"
              >
                <RemoveIcon />
              </IconButton>
            )}
          </Box>

          {renderPhotoGallery(pointIndex, point.photos)}
        </Box>
      ))}

      <Dialog 
        open={cameraDialogOpen} 
        onClose={handleCloseCamera}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography>Take Photo</Typography>
            <IconButton onClick={handleCloseCamera}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ position: 'relative', width: '100%', height: '400px' }}>
            {cameraError ? (
              <Typography color="error" align="center">
                {cameraError}
              </Typography>
            ) : (
              <video
                ref={videoRef}
                autoPlay
                playsInline
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  backgroundColor: '#000'
                }}
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCamera}>Cancel</Button>
          <Button 
            variant="contained" 
            onClick={handleCapture}
            disabled={!!cameraError}
          >
            Capture
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={uploadDialogOpen} 
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography>Uploading Photo</Typography>
            <Typography variant="body2" color="textSecondary">
              {uploadProgress}%
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ width: '100%', mt: 2 }}>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {uploadingFileName}
            </Typography>
            <LinearProgress 
              variant="determinate" 
              value={uploadProgress} 
              sx={{ height: 8, borderRadius: 4 }}
            />
          </Box>
        </DialogContent>
      </Dialog>

      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={isSubmitting}
        sx={{ marginTop: 3 }}
      >
        {isSubmitting ? (
          <>
            <CircularProgress size={24} sx={{ marginRight: 1 }} color="inherit" />
            Submitting...
          </>
        ) : (
          "Submit Homework"
        )}
      </Button>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Incomplete Information</DialogTitle>
        <DialogContent>
          <Typography>
            Please select a class, section, and subject before submitting the
            homework.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AddHomework;

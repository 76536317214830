import React from "react";
import { Link } from "react-router-dom";

import "./Css/HeaderHomepage.css";
import Logout from "./LogOut";

const HeaderHomepage = () => {
  return (
    <div className="header-homepage">
      <h1>
        <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
          SpellStudy
        </Link>
      </h1>
      <Logout />
    </div>
  );
};

export default HeaderHomepage;

import React from "react";
import { Radar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const SubjectwiseChart = ({ data }) => {
  function calculateAverageSubjectScores(data) {
    let subjectScores = {};

    data.forEach((item) => {
      item.report.forEach((report) => {
        if (report.subject && report.report) {
          if (!subjectScores[report.subject]) {
            subjectScores[report.subject] = {
              totalClassScore: 0,
              totalHomeworkScore: 0,
              countClassScore: 0,
              countHomeworkScore: 0,
            };
          }

          if (
            report.report.classScore !== undefined &&
            report.report.classScore !== 0
          ) {
            subjectScores[report.subject].totalClassScore +=
              report.report.classScore;
            subjectScores[report.subject].countClassScore++;
          }

          if (
            report.report.homeworkScore !== undefined &&
            report.report.homeworkScore !== 0
          ) {
            subjectScores[report.subject].totalHomeworkScore +=
              report.report.homeworkScore;
            subjectScores[report.subject].countHomeworkScore++;
          }
        }
      });
    });

    let averageSubjectScores = {};

    for (let subject in subjectScores) {
      averageSubjectScores[subject] = {
        averageClassScore:
          subjectScores[subject].countClassScore > 0
            ? subjectScores[subject].totalClassScore /
              subjectScores[subject].countClassScore
            : 0,
        averageHomeworkScore:
          subjectScores[subject].countHomeworkScore > 0
            ? subjectScores[subject].totalHomeworkScore /
              subjectScores[subject].countHomeworkScore
            : 0,
      };
    }

    return averageSubjectScores;
  }

  const subjectAverages = calculateAverageSubjectScores(data);
  console.log(subjectAverages);

  const labels = Object.keys(subjectAverages);
  const averageClassScores = labels.map(
    (subject) => subjectAverages[subject].averageClassScore
  );
  const averageHomeworkScores = labels.map(
    (subject) => subjectAverages[subject].averageHomeworkScore
  );

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Average Class Scores",
        data: averageClassScores,
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        pointRadius: 5,
        pointBackgroundColor: "rgba(75, 192, 192, 1)",
      },
      {
        label: "Average Homework Scores",
        data: averageHomeworkScores,
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
        pointRadius: 5,
        pointBackgroundColor: "rgba(255, 99, 132, 1)",
      },
    ],
  };

  const options = {
    plugins: {
      title: {
        display: true,
        text: "Subjectwise Average Scores", // Chart title
        font: {
          size: 20,
        },
      },
    },
    scales: {
      r: {
        beginAtZero: true,
        min: 0,
        max: 10,
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  return (
    <div className="table-component">
      <Radar data={chartData} options={options} />
    </div>
  );
};

export default SubjectwiseChart;

import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ShowPaymentRecord = ({ feeData }) => {
  if (!feeData || feeData.length === 0) {
    return (
      <Box textAlign="center" mt={4}>
        <Typography variant="h6" color="textSecondary">
          No payment records available.
        </Typography>
      </Box>
    );
  }

  const formatDate = (dateString) => {
    return new Intl.DateTimeFormat("en-IN", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    }).format(new Date(dateString));
  };

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Payment Records
      </Typography>
      {feeData.map((monthRecord) => {
        const month = new Date(monthRecord.month).toLocaleString("en-IN", {
          month: "long",
          year: "numeric",
        });

        return (
          <Accordion key={monthRecord._id}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel-${monthRecord._id}-content`}
              id={`panel-${monthRecord._id}-header`}
            >
              <Typography variant="h6">{month}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <strong>Date Paid</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Amount Paid</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Fee Type</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Paid By</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Payment Mode</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Receipt Number</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {monthRecord.feeDetails.map((fee) => (
                      <TableRow key={fee._id}>
                        <TableCell>{formatDate(fee.datePaid)}</TableCell>
                        <TableCell>₹{fee.amountPaid.toFixed(2)}</TableCell>
                        <TableCell>{fee.feeType}</TableCell>
                        <TableCell>{fee.paidBy}</TableCell>
                        <TableCell>{fee.paymentMode}</TableCell>
                        <TableCell>
                          {fee.receiptNumber || "Not Provided"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
};

export default ShowPaymentRecord;

import React, { useState, useEffect } from "react";
import axios from "axios";
import baseUrl from "./Configs";
import {
  TextField,
  Button,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Box,
  Grid,
  Typography,
  Divider,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import _ from "lodash";
import Header from "./Header";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  teacherSelectedClassState,
  teacherSelectedSubjectState,
  teacherSelectedSectionState,
  headerSelectorsState,
} from "./Atoms";

const EditStaff = (props) => {
  // State for staff list and selected staff
  const [staffList, setStaffList] = useState([]);
  const [selectedStaffId, setSelectedStaffId] = useState("");
  const [staffData, setStaffData] = useState(null); // original fetched data

  // Form field states
  const [staffName, setStaffName] = useState("");
  const [staffIdField, setStaffIdField] = useState("");
  const [staffEmail, setStaffEmail] = useState("");
  const [staffPhoneNumber, setStaffPhoneNumber] = useState("");
  const [staffAddress, setStaffAddress] = useState("");
  const [staffGender, setStaffGender] = useState("");
  const [staffDateOfBirth, setStaffDateOfBirth] = useState("");
  const [staffRole, setStaffRole] = useState("");
  const [password, setPassword] = useState(""); // leave blank if no change
  const [userStatus, setUserStatus] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [changesSummary, setChangesSummary] = useState([]);

  const [educationDetails, setEducationDetails] = useState([]);
  const [bankAccountDetails, setBankAccountDetails] = useState({
    accountNumber: "",
    bankName: "",
    ifscCode: "",
    upiId: "",
  });

  // API endpoints
  const getAllStaffUrl = `${baseUrl}/v1/staffroute/getallstaff`;
  const getStaffInfoUrl = `${baseUrl}/v1/staffroute/getstaffinfo`; // expects /:id appended
  const editStaffUrl = `${baseUrl}/v1/staffroute/editstaff`;

  const token = localStorage.getItem("token");

  // Create an Axios instance
  const axiosInstance = axios.create({
    baseURL: baseUrl,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  // Fetch the list of all staff members
  useEffect(() => {
    const fetchStaffList = async () => {
      try {
        const response = await axiosInstance.get(getAllStaffUrl);
        setStaffList(response.data);
      } catch (error) {
        console.error("Error fetching staff list:", error);
      }
    };
    fetchStaffList();
  }, [getAllStaffUrl]);

  // When a staff member is selected, fetch their details and prepopulate fields.
  useEffect(() => {
    if (selectedStaffId) {
      const fetchStaffInfo = async () => {
        try {
          const response = await axiosInstance.get(
            `${getStaffInfoUrl}/${selectedStaffId}`
          );
          const data = response.data;
          console.log("Staff Info:", data);
          setStaffData(data);
          // Prepopulate form fields from fetched data.
          setStaffName(data.name || "");
          setStaffIdField(data.staffId || "");
          setStaffEmail(data.email || "");
          setStaffPhoneNumber(data.phoneNumber || "");
          setStaffAddress(data.address || "");
          setStaffGender(data.gender || "");
          setUserStatus(data.userStatus || "");
          if (data.dateOfBirth) {
            const dob = new Date(data.dateOfBirth);
            const formattedDob = dob.toISOString().split("T")[0];
            setStaffDateOfBirth(formattedDob);
          } else {
            setStaffDateOfBirth("");
          }
          setStaffRole(data.role || "");
          // Do not prepopulate the password field
          setPassword("");
          // Prepopulate education details
          setEducationDetails(
            data.educationDetails && data.educationDetails.length > 0
              ? data.educationDetails
              : [{ institution: "", qualification: "", yearOfPassing: "" }]
          );
          // Prepopulate bank account details
          setBankAccountDetails(
            data.bankAccountDetails || {
              accountNumber: "",
              bankName: "",
              ifscCode: "",
              upiId: "",
            }
          );
        } catch (error) {
          console.error("Error fetching staff info:", error);
        }
      };
      fetchStaffInfo();
    }
  }, [selectedStaffId, getStaffInfoUrl]);

  // Handlers for education details
  const handleEducationChange = (index, event) => {
    const values = [...educationDetails];
    values[index][event.target.name] = event.target.value;
    setEducationDetails(values);
  };

  const addEducationDetail = () => {
    setEducationDetails([
      ...educationDetails,
      { institution: "", qualification: "", yearOfPassing: "" },
    ]);
  };

  const deleteEducationDetail = (index) => {
    const updated = educationDetails.filter((_, i) => i !== index);
    setEducationDetails(updated);
  };

  // Handler for bank account changes
  const handleBankAccountChange = (event) => {
    setBankAccountDetails({
      ...bankAccountDetails,
      [event.target.name]: event.target.value,
    });
  };

  // Form submission handler: only update if data has changed.
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate required fields.
    if (
      !staffName.trim() ||
      !staffEmail.trim() ||
      !staffPhoneNumber.trim() ||
      !staffGender.trim() ||
      !staffDateOfBirth.trim() ||
      !staffRole.trim()
    ) {
      alert("Please fill in all required fields.");
      // Auto-focus on the first empty field. For example, if staffName is empty:
      if (!staffName.trim()) {
        document.getElementById("staffName").focus();
      }
      return;
    }

    // Build updatedFields by comparing new values with the original staffData.
    const updatedFields = {};
    if (staffData) {
      if (staffName !== staffData.name) updatedFields.name = staffName;
      if (staffEmail !== staffData.email) updatedFields.email = staffEmail;
      if (staffPhoneNumber !== staffData.phoneNumber)
        updatedFields.phoneNumber = staffPhoneNumber;
      if (staffAddress !== (staffData.address || ""))
        updatedFields.address = staffAddress;
      if (staffGender !== staffData.gender) updatedFields.gender = staffGender;
      const formattedDOB = staffData.dateOfBirth
        ? new Date(staffData.dateOfBirth).toISOString().split("T")[0]
        : "";
      if (staffDateOfBirth !== formattedDOB)
        updatedFields.dateOfBirth = staffDateOfBirth;
      if (staffRole !== staffData.role) updatedFields.role = staffRole;
      if (userStatus !== staffData.userStatus)
        updatedFields.userStatus = userStatus;
      if (password.trim()) updatedFields.password = password;
      if (!_.isEqual(educationDetails, staffData.educationDetails)) {
        updatedFields.educationDetails = educationDetails;
      }
      if (
        !_.isEqual(
          bankAccountDetails,
          staffData.bankAccountDetails || {
            accountNumber: "",
            bankName: "",
            ifscCode: "",
            upiId: "",
          }
        )
      ) {
        updatedFields.bankAccountDetails = bankAccountDetails;
      }
    }

    if (Object.keys(updatedFields).length === 0) {
      alert("No changes detected.");
      return;
    }

    // Build changes summary (keeping keys as-is)
    const changes = [];
    for (const [key, value] of Object.entries(updatedFields)) {
      const oldValue =
        staffData[key] !== undefined ? staffData[key] : "Not set";
      changes.push({
        field: key,
        old: oldValue,
        new: value,
      });
    }
    setChangesSummary(changes);
    setOpenDialog(true);
  };

  const handleConfirmSubmit = async () => {
    setOpenDialog(false);
    const payload = {
      staffId: selectedStaffId,
      ...changesSummary.reduce((acc, change) => {
        acc[change.field] = change.new;
        return acc;
      }, {}),
    };

    try {
      console.log("Edit Staff Payload:", payload);
      const response = await fetch(editStaffUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      console.log("Edit Staff Response:", data);
      alert("Staff updated successfully!");
      props.onSubmit();
    } catch (error) {
      console.error("Error updating staff:", error);
      alert("Failed to update staff. Please try again.");
    }
  };

  const handleCloseDialog = () => setOpenDialog(false);

  return (
    <Box sx={{ maxWidth: 1200, margin: "auto", padding: 3 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Edit Staff
      </Typography>

      <FormControl fullWidth sx={{ mb: 3 }}>
        <InputLabel id="select-staff-label">Select Staff</InputLabel>
        <Select
          labelId="select-staff-label"
          value={selectedStaffId}
          label="Select Staff"
          onChange={(e) => setSelectedStaffId(e.target.value)}
        >
          {staffList.map((staff) => (
            <MenuItem key={staff.id} value={staff.id}>
              {staff.name} - {staff.role}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {selectedStaffId && (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                Personal Details
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <TextField
                id="staffName"
                label="Name"
                value={staffName}
                onChange={(e) => setStaffName(e.target.value)}
                fullWidth
                required
                sx={{ mb: 2 }}
              />
              <TextField
                label="Staff ID"
                value={staffIdField}
                onChange={(e) => setStaffIdField(e.target.value)}
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                label="Phone Number"
                value={staffPhoneNumber}
                onChange={(e) => setStaffPhoneNumber(e.target.value)}
                fullWidth
                required
                sx={{ mb: 2 }}
              />
              <TextField
                label="Address"
                value={staffAddress}
                onChange={(e) => setStaffAddress(e.target.value)}
                fullWidth
                sx={{ mb: 2 }}
              />
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="gender-label">Gender</InputLabel>
                <Select
                  labelId="gender-label"
                  value={staffGender}
                  onChange={(e) => setStaffGender(e.target.value)}
                  required
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="Date of Birth"
                type="date"
                value={staffDateOfBirth}
                onChange={(e) => setStaffDateOfBirth(e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
                required
                sx={{ mb: 2 }}
              />
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="user-status-label">User Status</InputLabel>
                <Select
                  labelId="user-status-label"
                  value={userStatus}
                  onChange={(e) => setUserStatus(e.target.value)}
                  required
                >
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                Role and Bank Details
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="role-label">Role</InputLabel>
                <Select
                  labelId="role-label"
                  value={staffRole}
                  onChange={(e) => setStaffRole(e.target.value)}
                  required
                >
                  <MenuItem value="admin">Admin</MenuItem>
                  <MenuItem value="teacher">Teacher</MenuItem>
                  <MenuItem value="clerk">Clerk</MenuItem>
                  <MenuItem value="librarian">Librarian</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="Account Number"
                name="accountNumber"
                value={bankAccountDetails.accountNumber}
                onChange={handleBankAccountChange}
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                label="Bank Name"
                name="bankName"
                value={bankAccountDetails.bankName}
                onChange={handleBankAccountChange}
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                label="IFSC Code"
                name="ifscCode"
                value={bankAccountDetails.ifscCode}
                onChange={handleBankAccountChange}
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                label="UPI ID"
                name="upiId"
                value={bankAccountDetails.upiId}
                onChange={handleBankAccountChange}
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                label="Email/Username"
                value={staffEmail}
                onChange={(e) => setStaffEmail(e.target.value)}
                fullWidth
                required
                sx={{ mb: 2 }}
              />
              <TextField
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                sx={{ mb: 2 }}
                helperText="Leave blank if you don't want to change the password."
              />
            </Grid>
          </Grid>

          <Box mt={4}>
            <Typography variant="h6" gutterBottom>
              Education Details
            </Typography>
            <Divider sx={{ mb: 2 }} />
            {educationDetails.map((edu, index) => (
              <Box
                key={index}
                sx={{ mb: 2, display: "flex", gap: 2, alignItems: "center" }}
              >
                <TextField
                  label="Institution"
                  name="institution"
                  value={edu.institution}
                  onChange={(e) => handleEducationChange(index, e)}
                  fullWidth
                  required
                />
                <TextField
                  label="Qualification"
                  name="qualification"
                  value={edu.qualification}
                  onChange={(e) => handleEducationChange(index, e)}
                  fullWidth
                  required
                />
                <TextField
                  label="Year of Passing"
                  name="yearOfPassing"
                  value={edu.yearOfPassing}
                  onChange={(e) => handleEducationChange(index, e)}
                  fullWidth
                  required
                />
                <IconButton
                  color="error"
                  onClick={() => deleteEducationDetail(index)}
                  aria-label="delete"
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
            <Button
              variant="outlined"
              color="primary"
              onClick={addEducationDetail}
            >
              Add Another Education Detail
            </Button>
          </Box>

          <Box mt={4} display="flex" justifyContent="center">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
            >
              Save Changes
            </Button>
          </Box>
        </form>
      )}

      {openDialog && (
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>Confirm Changes</DialogTitle>
          <DialogContent>
            <Typography variant="subtitle1" gutterBottom>
              You are about to make the following changes:
            </Typography>
            <List dense>
              {changesSummary.map((change, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={change.field}
                    secondary={`From: ${change.old} → To: ${change.new}`}
                  />
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
            <Button
              onClick={handleConfirmSubmit}
              color="primary"
              variant="contained"
            >
              Confirm Changes
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default EditStaff;

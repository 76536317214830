// AdditionalRating.js
import React, { useState, useEffect } from "react";
import baseUrl from "../Configs";

import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import "./Css/AddAdditionalRating.css";

import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import {
  receivedStudentListState,
  studentwiseDailyReportState,
  teacherSelectedSectionState,
  teacherSelectedClassState,
  teacherSelectedDateState,
  headerSelectorsState,
} from "../Atoms";

import Slider from "@mui/material/Slider";

const useStyles = makeStyles({
  red: {
    height: 2, // Change this to increase the height of the slider
    color: "#ff0000", // Change this to the color you want
    touchAction: "pan-y",
    padding: "0px",
  },
  blue: {
    touchAction: "pan-y",
    padding: "0px",
  },
  purple: {
    height: 2, // Change this to increase the height of the slider
    color: "#800080", // Change this to the color you want
    padding: "0px",
    touchAction: "pan-y",
  },
  dummy: {
    width: "100%",
    padding: "0px",
  },
});

const AdditionalRating = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const [selectedClass, setSelectedClass] = useRecoilState(
    teacherSelectedClassState
  );
  const [selectedSection, setSelectedSection] = useRecoilState(
    teacherSelectedSectionState
  );
  const [receivedStudentList, setReceivedStudentListState] = useRecoilState(
    receivedStudentListState
  );
  const setStudentwiseDailyReportState = useSetRecoilState(
    studentwiseDailyReportState
  );
  const teacherSelectedDate = useRecoilValue(teacherSelectedDateState);

  const [scores, setScores] = useState({});
  const [selectedSliders, setSelectedSliders] = useState([]);
  const [headerSelectors, setHeaderSelectors] =
    useRecoilState(headerSelectorsState);
  const classes = useStyles();

  const handleSelectionChange = (event) => {
    setSelectedSliders(event.target.value);
  };

  let API_URL_ADDITIONAL_RATING = `${baseUrl}/v1/studentroute/updateadditionalreport`;

  const updateStudentwiseScore = (studentId, type, score) => {
    setScores((prevScores) => ({
      ...prevScores,
      [studentId]: {
        ...prevScores[studentId],
        rollNumber: studentId,
        [type]: score,
      },
    }));
  };

  const fetchStudents = async () => {
    const getStudentsUrl = `${baseUrl}/v1/classroute/getstudentlist?className=${selectedClass}&section=${selectedSection}`;
    const response = await fetch(getStudentsUrl, optionsForGet);
    const data = await response.json();
    setReceivedStudentListState(data);
  };
  const optionsForGet = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };
  const submitScores = async () => {
    // Build the report payload
    const additionalReport = {
      className: selectedClass,
      section: selectedSection,
      date: teacherSelectedDate,
      additionalReport: Object.values(scores),
    };

    const options = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(additionalReport),
    };

    console.log("Submitting additional report:", additionalReport);
    setIsLoading(true);
    try {
      const response = await fetch(API_URL_ADDITIONAL_RATING, options);
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText);
      }
      // const jsonData = await response.json();
      // console.log("Response from server:", jsonData);
      // Reset values on successful submit
      setScores({});
      setSelectedSliders([]);
      props.onSubmit();
    } catch (e) {
      alert("Failed to submit scores: " + e.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setStudentwiseDailyReportState(Object.values(scores));
  }, [scores, setStudentwiseDailyReportState]);
  useEffect(() => {
    if (receivedStudentList.length === 0) {
      fetchStudents();
    }
  }, [selectedClass, selectedSection]);

  // Set header selectors to show class and section selectors
  useEffect(() => {
    setTimeout(() => {
      setHeaderSelectors({
        classSelector: true,
        sectionSelector: true,
        subjectSelector: false,
        dateSelector: true,
        backMethod: props.handleBackClick,
      });
    }, 10);
  }, []);

  return (
    <div className="additional-rating">
      <div id="additional-rating_form-control-container">
        <FormControl id="additional-rating_form-control">
          <InputLabel id="demo-mutiple-checkbox-label">Sliders</InputLabel>
          <Select
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-checkbox"
            multiple
            value={selectedSliders}
            onChange={handleSelectionChange}
            renderValue={(selected) =>
              selected.map((value) => value.replace(" Score", "")).join(", ")
            }
          >
            <MenuItem value="Moral Values Score">
              <Checkbox
                checked={selectedSliders.indexOf("Moral Values Score") > -1}
              />
              <ListItemText primary="Moral Values Score" />
            </MenuItem>
            <MenuItem value="Sports Score">
              <Checkbox
                checked={selectedSliders.indexOf("Sports Score") > -1}
              />
              <ListItemText primary="Sports Score" />
            </MenuItem>
            <MenuItem value="Communication Skills Score">
              <Checkbox
                checked={
                  selectedSliders.indexOf("Communication Skills Score") > -1
                }
              />
              <ListItemText primary="Communication Skills Score" />
            </MenuItem>
          </Select>
        </FormControl>
      </div>
      <ul className="additional-rating_student-list-root">
        {receivedStudentList &&
          receivedStudentList.map((student) => {
            return (
              <li
                id="additional-rating_student-list-item"
                key={student.rollNumber}
              >
                <div id="additional-rating_student-list-item-header">
                  <span>{student.name} </span>
                  <span>{student.rollNumber}</span>
                </div>
                <div className="additional-rating_all-slider-container">
                  {selectedSliders.includes("Moral Values Score") && (
                    <div className="additional-rating_slider-title-container">
                      <span>M</span>
                      <div className="additional-rating_slider-container">
                        <Slider
                          className={classes.blue}
                          aria-label="Moral Values Score"
                          defaultValue={0}
                          valueLabelDisplay="auto"
                          step={1}
                          marks
                          min={0}
                          max={10}
                          onChange={(e) =>
                            updateStudentwiseScore(
                              student.rollNumber,
                              "moralScienceScore",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                  )}
                  {selectedSliders.includes("Sports Score") && (
                    <div className="additional-rating_slider-title-container">
                      <span>S</span>
                      <div className="additional-rating_slider-container">
                        <Slider
                          className={classes.red}
                          aria-label="Sports Score"
                          defaultValue={0}
                          valueLabelDisplay="auto"
                          step={1}
                          marks
                          min={0}
                          max={10}
                          onChange={(e) =>
                            updateStudentwiseScore(
                              student.rollNumber,
                              "sportsScore",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                  )}
                  {selectedSliders.includes("Communication Skills Score") && (
                    <div className="additional-rating_slider-title-container">
                      <span>C</span>
                      <div className="additional-rating_slider-container">
                        <Slider
                          className={classes.purple}
                          aria-label="Communication Skills Score"
                          defaultValue={0}
                          valueLabelDisplay="auto"
                          step={1}
                          marks
                          min={0}
                          max={10}
                          onChange={(e) =>
                            updateStudentwiseScore(
                              student.rollNumber,
                              "communicationSkillsScore",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
              </li>
            );
          })}
      </ul>
      <button
        onClick={submitScores}
        disabled={isLoading}
        className="submit-additional-rating"
      >
        {isLoading ? (
          <CircularProgress size={20} color="inherit" />
        ) : (
          "Submit Scores"
        )}
      </button>
    </div>
  );
};

export default AdditionalRating;

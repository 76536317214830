import { motion } from "framer-motion";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Container,
  Grid,
  Box,
} from "@mui/material";
import { Login } from "@mui/icons-material";
import { display, flexbox } from "@mui/system";

const features = [
  {
    title: "📊 AI-Powered Analytics",
    description:
      "Gain deep insights into student progress, attendance, and academic performance.",
  },
  {
    title: "🎯 Personalized Coaching",
    description:
      "Tailored study plans and smart recommendations for every student.",
  },
  {
    title: "💡 Smart Parent Dashboard",
    description: "Stay updated with AI-driven reports and progress tracking.",
  },
];

function FeatureCard({ title, description }) {
  return (
    <motion.div whileHover={{ scale: 1.05 }}>
      <Card
        sx={{
          backgroundColor: "background.paper",
          p: 3,
          textAlign: "center",
          borderRadius: "12px",
          boxShadow: "0px 4px 10px rgba(255, 64, 129, 0.4)",
        }}
      >
        <CardContent>
          <Typography variant="h6" color="primary" gutterBottom>
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {description}
          </Typography>
        </CardContent>
      </Card>
    </motion.div>
  );
}

export default function AiLoginScreen({ onGetStarted }) {
  return (
    <Box
      sx={{
        background: "linear-gradient(135deg, #000428 30%, #004e92 90%)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        color: "white",
        p: 3,
        borderRadius: "12px",
        minHeight: "600px",
        boxShadow: "0px 4px 15px rgba(0, 229, 255, 0.3)",
        padding: "24px 0",
      }}
    >
      <Container maxWidth="md">
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}

          // css-2njx9m {
        >
          <Typography variant="h1" color="primary" gutterBottom>
            Welcome to SpellStudy
          </Typography>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.8 }}
        >
          <Typography variant="h5" color="textSecondary" paragraph>
            Unlock the future of education with AI-powered insights,
            personalized coaching, and real-time analytics.
          </Typography>
        </motion.div>

        <Grid
          container
          spacing={4}
          justifyContent="center"
          direction="column"
          sx={{ mt: 4 }}
        >
          {features.map((feature, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <FeatureCard
                title={feature.title}
                description={feature.description}
              />
            </Grid>
          ))}
        </Grid>
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 1.5, duration: 0.8 }}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{
              mt: 4,
              px: 4,
              py: 1.5,
              fontSize: "1.2rem",
              borderRadius: "8px",
              boxShadow: "0px 4px 15px rgba(0, 229, 255, 0.5)",
              width: "100%",
            }}
            startIcon={<Login />}
            onClick={onGetStarted} // Call the passed callback
          >
            Get Started
          </Button>
        </motion.div>
      </Container>
    </Box>
  );
}

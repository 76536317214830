import React, { useState, useEffect } from "react";
import baseUrl from "./Configs";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Box,
} from "@mui/material";
import LazyLoad from "react-lazyload";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  receivedClassListState,
  headerSelectorsState,
  teacherSelectedDateState,
} from "./Atoms";
import Header from "./Header";
import { toTitleCase } from "./Utils";
import moment from "moment";

const feeTypes = ["tuitionFee", "transportFee", "libraryFee", "sportsFee"];
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

/**
 * DualProgressBar renders a horizontal progress bar with a red background and a green overlay.
 * @param {Object} props
 * @param {number} props.donePercentage - A number (0 to 100) representing the completed percentage.
 */
const DualProgressBar = ({ donePercentage }) => {
  return (
    <div
      style={{
        backgroundColor: "red",
        borderRadius: "4px",
        overflow: "hidden",
        height: "12px",
        width: "100%",
        margin: "4px 0",
      }}
    >
      <div
        style={{
          width: `${donePercentage}%`,
          backgroundColor: "green",
          height: "100%",
          transition: "width 0.5s ease-in-out",
        }}
      ></div>
    </div>
  );
};

/**
 * Capsule wraps content in a rounded, shaded box.
 * @param {Object} props
 * @param {string} props.title - The title of the capsule.
 * @param {React.ReactNode} props.children - The content to display inside.
 */
const Capsule = ({ title, children }) => {
  return (
    <Box
      sx={{
        border: "1px solid #ccc",
        borderRadius: "20px",
        p: 1,
        mb: 2,
        backgroundColor: "#f9f9f9",
      }}
    >
      {title && (
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
          {title}
        </Typography>
      )}
      {children}
    </Box>
  );
};

/* --- Child Components --- */

/**
 * FeeCapsule displays fee-related metrics.
 */
const FeeCapsule = React.memo(({ classItem }) => {
  const expectedFee = classItem.expectedTotal || 0;
  const collectedFee = classItem.collectedTotal || 0;
  const feePercentage =
    expectedFee > 0 ? Math.min((collectedFee / expectedFee) * 100, 100) : 0;

  return (
    <Capsule title="Fee Status">
      <Typography variant="body2">
        <strong>Expected Total Fee:</strong> {expectedFee}
      </Typography>
      <Typography variant="body2">
        <strong>Collected Total Fee:</strong> {collectedFee}
      </Typography>
      <Typography variant="body2">
        <strong>Fee Collection Progress:</strong> {feePercentage.toFixed(2)}%
      </Typography>
      <DualProgressBar donePercentage={feePercentage} />
    </Capsule>
  );
});

/**
 * AttendanceCapsule displays attendance-related metrics.
 */
const AttendanceCapsule = React.memo(({ attendanceRecord }) => {
  if (!attendanceRecord) {
    return (
      <Capsule title="Attendance">
        <Typography variant="body2">Attendance data not available.</Typography>
      </Capsule>
    );
  }
  const attendancePercentage =
    attendanceRecord.totalStudents > 0
      ? Math.min(
          (attendanceRecord.totalPresent / attendanceRecord.totalStudents) *
            100,
          100
        )
      : 0;
  return (
    <Capsule title="Attendance">
      <Typography variant="body2">
        <strong>Total Students:</strong> {attendanceRecord.totalStudents}
      </Typography>
      <Typography variant="body2">
        <strong>Total Present:</strong> {attendanceRecord.totalPresent}
      </Typography>
      <Typography variant="body2">
        <strong>Attendance Progress:</strong> {attendancePercentage.toFixed(2)}%
      </Typography>
      <DualProgressBar donePercentage={attendancePercentage} />
    </Capsule>
  );
});

/**
 * SyllabusCapsule displays syllabus completion metrics.
 */
const SyllabusCapsule = React.memo(({ syllabusRecord }) => {
  if (!syllabusRecord) {
    return (
      <Capsule title="Syllabus Completion">
        <Typography variant="body2">Syllabus data not available.</Typography>
      </Capsule>
    );
  }
  return (
    <Capsule title="Syllabus Completion">
      <Typography variant="body2">
        <strong>Overall Syllabus Completion:</strong>{" "}
        {syllabusRecord.overallPercentage.toFixed(2)}%
      </Typography>
      <DualProgressBar donePercentage={syllabusRecord.overallPercentage} />
      {syllabusRecord.subjectCompletions &&
      syllabusRecord.subjectCompletions.length > 0 ? (
        syllabusRecord.subjectCompletions.map((sub, index) => (
          <div key={index}>
            <Typography variant="body2">
              <strong>{sub.subject}:</strong>{" "}
              {sub.completionPercentage.toFixed(2)}%
            </Typography>
            <DualProgressBar donePercentage={sub.completionPercentage} />
          </div>
        ))
      ) : (
        <Typography variant="body2">
          No subject syllabus data available.
        </Typography>
      )}
    </Capsule>
  );
});

/* --- Main Component --- */

const ClasswiseStatusCard = () => {
  const receivedClassList = useRecoilValue(receivedClassListState);
  const date = useRecoilValue(teacherSelectedDateState);
  const formattedDate = moment(date).format("YYYY-MM-DD");

  const [feeType, setFeeType] = useState(feeTypes[0]);
  const [monthIndex, setMonthIndex] = useState(new Date().getMonth());
  const [classwiseData, setClasswiseData] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [combinedSyllabusData, setCombinedSyllabusData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [attendanceLoading, setAttendanceLoading] = useState(false);
  const [headerSelectors, setHeaderSelectors] =
    useRecoilState(headerSelectorsState);

  const optionsForGet = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const getAllClassesAttendanceForDateUrl = `${baseUrl}/v1/classroute/getallclassesattendacefordate?date=${formattedDate}`;
  const getCombinedSyllabusCompletionUrl = `${baseUrl}/v1/syllabusroute/getcombinedsyllabuscompletionforallclasses`;

  // Fee data effect – changes when feeType or monthIndex change.
  useEffect(() => {
    const fetchClasswiseFeeStatus = async () => {
      setLoading(true);
      try {
        const responses = await Promise.all(
          receivedClassList.map(async (classItem) => {
            const url = `${baseUrl}/v1/feeroute/getfeestatusofclass?monthIndex=${monthIndex}&classId=${classItem.classId}&feeType=${feeType}`;
            const response = await fetch(url, optionsForGet);
            const data = await response.json();
            return { ...classItem, ...data };
          })
        );
        setClasswiseData(responses);
      } catch (error) {
        console.error("Error fetching classwise fee status:", error);
      }
      setLoading(false);
    };

    if (receivedClassList.length > 0) {
      fetchClasswiseFeeStatus();
    }
  }, [feeType, monthIndex, receivedClassList]);

  // Attendance data effect – changes when date changes.
  useEffect(() => {
    const fetchAttendanceData = async () => {
      setAttendanceLoading(true);
      try {
        const response = await fetch(
          getAllClassesAttendanceForDateUrl,
          optionsForGet
        );
        const data = await response.json();
        setAttendanceData(data);
      } catch (error) {
        console.error("Error fetching attendance data:", error);
      }
      setAttendanceLoading(false);
    };

    if (date) {
      fetchAttendanceData();
    }
  }, [date, getAllClassesAttendanceForDateUrl]);

  // Syllabus data effect – independent of fee and attendance.
  useEffect(() => {
    const fetchCombinedSyllabusCompletion = async () => {
      try {
        const response = await fetch(
          getCombinedSyllabusCompletionUrl,
          optionsForGet
        );
        const data = await response.json();
        setCombinedSyllabusData(data.overallCompletionByClass || []);
      } catch (error) {
        console.error("Error fetching combined syllabus completion:", error);
      }
    };
    fetchCombinedSyllabusCompletion();
  }, [getCombinedSyllabusCompletionUrl]);

  // Set header selectors on mount.
  useEffect(() => {
    setHeaderSelectors({
      classSelector: false,
      sectionSelector: false,
      subjectSelector: false,
      dateSelector: true,
      backMethod: null,
    });
  }, [setHeaderSelectors]);

  // Combined loading state.
  const combinedLoading = loading || attendanceLoading;

  return (
    <div>
      <Header
        selectors={{
          classSelector: false,
          sectionSelector: false,
          subjectSelector: false,
        }}
      />
      <div style={{ padding: "20px" }}>
        <h2>Classwise Fee, Attendance & Syllabus Completion Status</h2>

        {/* Dropdown selectors */}
        <Grid container spacing={2} style={{ marginBottom: "20px" }}>
          <Grid item xs={6} sm={3}>
            <FormControl fullWidth>
              <InputLabel>Fee Type</InputLabel>
              <Select
                value={feeType}
                label="Fee Type"
                onChange={(e) => setFeeType(e.target.value)}
              >
                {feeTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {toTitleCase(type)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={3}>
            <FormControl fullWidth>
              <InputLabel>Month</InputLabel>
              <Select
                value={monthIndex}
                label="Month"
                onChange={(e) => setMonthIndex(e.target.value)}
              >
                {months.map((month, index) => (
                  <MenuItem key={month} value={index}>
                    {month}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {/* Display data in cards */}
        {combinedLoading ? (
          <CircularProgress />
        ) : (
          <Grid container spacing={2}>
            {classwiseData.length > 0 ? (
              classwiseData.map((classItem) => {
                // Fee data
                // Match attendance record based on classId.
                const attendanceRecord = attendanceData.find(
                  (record) => String(record._id) === String(classItem.classId)
                );
                // Syllabus data – match by className & section.
                const syllabusRecord = combinedSyllabusData.find(
                  (record) =>
                    record.className === classItem.className &&
                    record.section === classItem.section
                );

                return (
                  <Grid item xs={12} sm={6} md={4} key={classItem.classId}>
                    <LazyLoad
                      height={300}
                      offset={100}
                      placeholder={<CircularProgress />}
                    >
                      <Card
                        variant="outlined"
                        elevation={4}
                        sx={{ boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.3)" }}
                      >
                        <CardContent>
                          <Typography variant="h6" gutterBottom>
                            {classItem.className} - {classItem.section}
                          </Typography>
                          <FeeCapsule classItem={classItem} />
                          <AttendanceCapsule
                            attendanceRecord={attendanceRecord}
                          />
                          <SyllabusCapsule syllabusRecord={syllabusRecord} />
                        </CardContent>
                        <CardActions>
                          {/* Card actions if needed */}
                        </CardActions>
                      </Card>
                    </LazyLoad>
                  </Grid>
                );
              })
            ) : (
              <Grid item xs={12}>
                <Typography align="center">No data available</Typography>
              </Grid>
            )}
          </Grid>
        )}
      </div>
    </div>
  );
};

export default ClasswiseStatusCard;

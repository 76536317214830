import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tabs,
  Tab,
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  CircularProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import baseUrl from "./Configs";
import { useRecoilValue } from "recoil";
import { teacherSelectedClassState } from "./Atoms";

const TimeTableEditor = () => {
  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const [selectedDay, setSelectedDay] = useState("Monday");
  // Rows: each row represents a class entry (pre-populated if timetable data exists)
  const [rows, setRows] = useState(
    Array.from({ length: 5 }, () => ({
      className: "",
      section: "",
      periods: [],
    }))
  );
  const [timeSlots, setTimeSlots] = useState([]);
  const [groupedClasses, setGroupedClasses] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [resizingCol, setResizingCol] = useState(null);
  const [resizeTooltip, setResizeTooltip] = useState({
    open: false,
    value: "",
  });
  const tableRef = useRef();
  const selectedClass = useRecoilValue(teacherSelectedClassState);
  // Track if any changes have been made
  const [isDirty, setIsDirty] = useState(false);

  const getAllTeachersUrl = `${baseUrl}/v1/staffroute/getallteachers`;
  const getClassesUrl = `${baseUrl}/v1/classroute/getavailableclasses`;
  const saveTimeTableUrl = `${baseUrl}/v1/schoolroute/savetimetable`;
  const getTimeTableUrl = `${baseUrl}/v1/schoolroute/gettimetable`;

  const optionsForGet = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  // New state to hold fetched timetable data from API
  const [fetchedTimeTable, setFetchedTimeTable] = useState([]);

  // New state for deletion confirmation dialog
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [periodIdToDelete, setPeriodIdToDelete] = useState(null);

  // Helper: Add minutes to a "HH:MM" time string.
  const addMinutes = (timeStr, minsToAdd) => {
    const [hours, minutes] = timeStr.split(":").map(Number);
    let totalMinutes = hours * 60 + minutes + minsToAdd;
    if (totalMinutes < 0) totalMinutes = 0;
    const newHours = Math.floor(totalMinutes / 60);
    const newMinutes = totalMinutes % 60;
    return `${newHours.toString().padStart(2, "0")}:${newMinutes
      .toString()
      .padStart(2, "0")}`;
  };

  // Group classes as in your Header component
  const groupClasses = (classes) => {
    return classes.reduce((grouped, cls) => {
      const idx = grouped.findIndex((g) => g.className === cls.className);
      if (idx === -1) {
        grouped.push({
          className: cls.className,
          sectionsAndSubjects: [
            {
              section: cls.section,
              subjects: cls.subjects,
              classId: cls.classId,
            },
          ],
        });
      } else {
        grouped[idx].sectionsAndSubjects.push({
          section: cls.section,
          subjects: cls.subjects,
          classId: cls.classId,
        });
      }
      return grouped;
    }, []);
  };

  // Fetch classes and group them
  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const response = await fetch(getClassesUrl, optionsForGet);
        const data = await response.json();
        const grouped = groupClasses(data);
        setGroupedClasses(grouped);
      } catch (err) {
        console.error("Error fetching classes:", err);
      }
    };
    fetchClasses();
  }, []);

  // Fetch teachers
  useEffect(() => {
    fetch(getAllTeachersUrl, optionsForGet)
      .then((response) => response.json())
      .then((data) => setTeachers(data))
      .catch((err) => console.error("Error fetching teachers:", err));
  }, []);

  // Fetch existing timetable data and store it
  useEffect(() => {
    const fetchTimeTable = async () => {
      try {
        const response = await fetch(getTimeTableUrl, optionsForGet);
        const result = await response.json();
        if (result.success) {
          setFetchedTimeTable(result.data);
        }
      } catch (err) {
        console.error("Error fetching timetable:", err);
      }
    };
    fetchTimeTable();
  }, []);

  // Pre-populate rows and timeSlots based on fetched timetable data for the selected day.
  // It now supports both the older format and the new format (which includes className and section).
  useEffect(() => {
    // Wait until either groupedClasses or fetchedTimeTable is loaded.
    if (fetchedTimeTable.length === 0 && groupedClasses.length === 0) return;
    const dayEntries = fetchedTimeTable.filter(
      (entry) => entry.day === selectedDay
    );
    let newRows = dayEntries.map((entry) => {
      // If entry has new format properties, use them.
      if (entry.className && entry.section) {
        return {
          className: entry.className,
          section: entry.section,
          periods: entry.periods,
        };
      }
      // Otherwise, fall back to the earlier lookup using groupedClasses.
      const grp = groupedClasses.find((cls) =>
        cls.sectionsAndSubjects.some((sas) => sas.classId === entry.class)
      );
      if (grp) {
        const sas = grp.sectionsAndSubjects.find(
          (sas) => sas.classId === entry.class
        );
        return {
          className: grp.className,
          section: sas.section,
          periods: entry.periods,
        };
      } else {
        return {
          className: entry.class,
          section: "",
          periods: entry.periods,
        };
      }
    });
    while (newRows.length < 5) {
      newRows.push({ className: "", section: "", periods: [] });
    }
    setRows(newRows);

    if (dayEntries.length > 0 && dayEntries[0].periods?.length > 0) {
      const newTimeSlots = dayEntries[0].periods.map((p) => ({
        id: p.periodNumber,
        startTime: p.startTime,
        endTime: p.endTime,
        width: 150,
      }));
      setTimeSlots(newTimeSlots);
    }
  }, [fetchedTimeTable, selectedDay, groupedClasses]);

  // Remove a period (time slot) from header and all rows
  const removePeriod = (slotId) => {
    setTimeSlots((prev) => prev.filter((slot) => slot.id !== slotId));
    setRows((prevRows) =>
      prevRows.map((row) => ({
        ...row,
        periods: row.periods.filter((p) => p.periodNumber !== slotId),
      }))
    );
    setIsDirty(true);
  };

  // Open delete dialog when delete icon is clicked
  const handleDeleteClick = (slotId) => {
    setPeriodIdToDelete(slotId);
    setDeleteDialogOpen(true);
  };

  // Confirm deletion: remove period and close dialog
  const handleConfirmDelete = () => {
    if (periodIdToDelete !== null) {
      removePeriod(periodIdToDelete);
    }
    setDeleteDialogOpen(false);
    setPeriodIdToDelete(null);
  };

  // Cancel deletion: close dialog without removing
  const handleCancelDelete = () => {
    setDeleteDialogOpen(false);
    setPeriodIdToDelete(null);
  };

  // Get available sections for a given class
  const getAvailableSections = (className) => {
    const classObj = groupedClasses.find((cls) => cls.className === className);
    if (!classObj) return [];
    return classObj.sectionsAndSubjects.map((sas) => sas.section);
  };

  // Get available subjects for a given class and section
  const getAvailableSubjects = (className, section) => {
    const classObj = groupedClasses.find((cls) => cls.className === className);
    if (!classObj) return [];
    const sectionObj = classObj.sectionsAndSubjects.find(
      (sas) => sas.section === section
    );
    if (!sectionObj) return [];
    return sectionObj.subjects.map((subject) => subject.subjectName);
  };

  // Update class selection for a given row
  const handleClassSelect = (event, rowIndex) => {
    const selectedClassVal = event.target.value;
    setRows((prev) => {
      const newRows = [...prev];
      newRows[rowIndex] = {
        ...newRows[rowIndex],
        className: selectedClassVal,
        section: "",
        periods: [],
      };
      return newRows;
    });
    setIsDirty(true);
  };

  // Update section selection for a given row
  const handleSectionSelect = (event, rowIndex) => {
    const selectedSection = event.target.value;
    setRows((prev) => {
      const newRows = [...prev];
      newRows[rowIndex] = {
        ...newRows[rowIndex],
        section: selectedSection,
        periods: [],
      };
      return newRows;
    });
    setIsDirty(true);
  };

  // Update day selection from Tabs
  const handleDayChange = (event, newValue) => {
    setSelectedDay(newValue);
  };

  // Update cell (subject/teacher/room) for a given row and period
  const handleCellChange = (rowIndex, periodNumber, field, value) => {
    setRows((prev) => {
      const newRows = [...prev];
      const row = { ...newRows[rowIndex] };
      const periodIndex = row.periods.findIndex(
        (p) => p.periodNumber === periodNumber
      );
      if (periodIndex === -1) {
        row.periods.push({ periodNumber, [field]: value });
      } else {
        row.periods[periodIndex] = {
          ...row.periods[periodIndex],
          [field]: value,
        };
      }
      newRows[rowIndex] = row;
      return newRows;
    });
    setIsDirty(true);
  };

  // Update time slot header
  const handleTimeSlotChange = (slotId, field, value) => {
    setTimeSlots((prev) =>
      prev.map((slot) =>
        slot.id === slotId ? { ...slot, [field]: value } : slot
      )
    );
    setIsDirty(true);
  };

  // Adjust the end time of a period by a given offset (in minutes)
  const adjustEndTime = (slotId, offset) => {
    setTimeSlots((prev) =>
      prev.map((slot) => {
        if (slot.id === slotId) {
          const newEnd = addMinutes(slot.endTime, offset);
          // Ensure new end time is not before start time.
          if (newEnd < slot.startTime) return slot;
          return { ...slot, endTime: newEnd };
        }
        return slot;
      })
    );
    setIsDirty(true);
  };

  // Add a new period.
  // The new period's start time is the last period's end time (if exists) and default duration is 15 minutes.
  const addPeriod = () => {
    const newSlotId = timeSlots.length
      ? Math.max(...timeSlots.map((s) => s.id)) + 1
      : 1;
    let newStartTime = "00:00";
    let newEndTime = "00:15";
    if (timeSlots.length > 0) {
      const lastSlot = timeSlots[timeSlots.length - 1];
      newStartTime = lastSlot.endTime;
      newEndTime = addMinutes(lastSlot.endTime, 15);
    }
    // Update header
    setTimeSlots((prev) => [
      ...prev,
      {
        id: newSlotId,
        startTime: newStartTime,
        endTime: newEndTime,
        width: 150,
      },
    ]);
    // Also update every row with a new period entry (default values)
    setRows((prevRows) =>
      prevRows.map((row) => ({
        ...row,
        periods: [
          ...row.periods,
          { periodNumber: newSlotId, subject: "", teacher: "", room: "" },
        ],
      }))
    );
    setIsDirty(true);
  };

  // Column resizing handlers – moved to a dedicated resize handle
  const handleMouseDown = (e, slotId) => {
    setResizingCol(slotId);
    e.preventDefault();
    e.stopPropagation();
  };

  const handleMouseMove = (e) => {
    if (resizingCol !== null) {
      const tableRect = tableRef.current.getBoundingClientRect();
      const newWidth = Math.max(e.clientX - tableRect.left - 100, 50);
      setTimeSlots((prev) =>
        prev.map((slot) =>
          slot.id === resizingCol ? { ...slot, width: newWidth } : slot
        )
      );
      const duration = Math.round((newWidth / 10) * 5);
      setResizeTooltip({ open: true, value: `${duration} mins` });
    }
  };

  const handleMouseUp = () => {
    setResizingCol(null);
    setResizeTooltip({ open: false, value: "" });
  };

  useEffect(() => {
    if (resizingCol !== null) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    } else {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    }
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [resizingCol]);

  // Save timetable: For each non-empty row, build payload and filter out completely empty periods.
  const handleSave = async () => {
    setLoading(true);
    try {
      const promises = rows.map(async (row) => {
        if (!row.className) return null; // Skip empty rows

        const classObj = groupedClasses.find(
          (cls) => cls.className === row.className
        );
        const sectionObj = classObj
          ? classObj.sectionsAndSubjects.find(
              (sas) => sas.section === row.section
            )
          : null;
        const classId = sectionObj ? sectionObj.classId : row.className;

        // Build periods payload using timeSlots as the reference
        const periods = timeSlots
          .map((slot) => {
            const period = row.periods.find((p) => p.periodNumber === slot.id);
            if (!period || (!period.subject && !period.teacher)) {
              return null;
            }
            return {
              periodNumber: slot.id,
              subject: period.subject || "",
              teacher: period.teacher || "",
              startTime: slot.startTime,
              endTime: slot.endTime,
              room: period.room || "",
            };
          })
          .filter((p) => p !== null);

        if (periods.length === 0) return null;

        const payload = {
          class: classId,
          day: selectedDay,
          periods: periods.sort((a, b) => a.periodNumber - b.periodNumber),
        };

        const res = await fetch(saveTimeTableUrl, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify(payload),
        });
        return res.json();
      });

      const results = await Promise.all(promises);
      const filteredResults = results.filter((result) => result !== null);
      const allSuccess = filteredResults.every((result) => result.success);
      if (allSuccess) {
        alert("Timetable saved successfully for all classes");
        setIsDirty(false);
      } else {
        alert(
          "Some classes failed to save. Please check the console for details."
        );
        console.error("Save results:", results);
      }
    } catch (err) {
      console.error("Error saving timetable:", err);
      alert("Error saving timetable");
    }
    setLoading(false);
  };

  return (
    <Box sx={{ width: "100%", p: 2 }}>
      <Tabs
        value={selectedDay}
        onChange={handleDayChange}
        variant="scrollable"
        scrollButtons="auto"
        sx={{ mb: 2 }}
      >
        {daysOfWeek.map((day) => (
          <Tab key={day} label={day} value={day} />
        ))}
      </Tabs>

      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table ref={tableRef}>
            <TableHead>
              <TableRow>
                <TableCell>Class &amp; Section</TableCell>
                {timeSlots.map((slot) => (
                  <TableCell key={slot.id}>
                    <Box sx={{ position: "relative" }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                        }}
                      >
                        <TextField
                          size="small"
                          label="Start Time"
                          value={slot.startTime}
                          onChange={(e) =>
                            handleTimeSlotChange(
                              slot.id,
                              "startTime",
                              e.target.value
                            )
                          }
                        />
                        <TextField
                          size="small"
                          label="End Time"
                          value={slot.endTime}
                          onChange={(e) =>
                            handleTimeSlotChange(
                              slot.id,
                              "endTime",
                              e.target.value
                            )
                          }
                        />
                        <Box sx={{ display: "flex", gap: 0.5 }}>
                          <IconButton
                            onClick={() => adjustEndTime(slot.id, -15)}
                            size="small"
                          >
                            -15
                          </IconButton>
                          <IconButton
                            onClick={() => adjustEndTime(slot.id, 15)}
                            size="small"
                          >
                            +15
                          </IconButton>
                        </Box>
                        <IconButton
                          onClick={() => handleDeleteClick(slot.id)}
                          size="small"
                          sx={{ alignSelf: "flex-start" }}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Box>
                      {/* Dedicated resize handle */}
                      <Box
                        onMouseDown={(e) => handleMouseDown(e, slot.id)}
                        sx={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          bottom: 0,
                          width: 5,
                          cursor: "col-resize",
                        }}
                      />
                    </Box>
                  </TableCell>
                ))}
                <TableCell>
                  <IconButton onClick={addPeriod} color="primary">
                    <AddIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  <TableCell>
                    <Box
                      sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                    >
                      <FormControl fullWidth size="small">
                        <InputLabel>Class</InputLabel>
                        <Select
                          value={row.className}
                          label="Class"
                          onChange={(e) => handleClassSelect(e, rowIndex)}
                        >
                          {groupedClasses.map((cls, idx) => (
                            <MenuItem key={idx} value={cls.className}>
                              {cls.className}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl fullWidth size="small">
                        <InputLabel>Section</InputLabel>
                        <Select
                          value={row.section}
                          label="Section"
                          onChange={(e) => handleSectionSelect(e, rowIndex)}
                          disabled={!row.className}
                        >
                          {getAvailableSections(row.className).map(
                            (section, idx) => (
                              <MenuItem key={idx} value={section}>
                                {section}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </Box>
                  </TableCell>
                  {timeSlots.map((slot) => {
                    const periodData =
                      row.periods.find((p) => p.periodNumber === slot.id) || {};
                    return (
                      <TableCell key={slot.id}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                          }}
                        >
                          <FormControl size="small">
                            <InputLabel>Subject</InputLabel>
                            <Select
                              value={periodData.subject || ""}
                              label="Subject"
                              onChange={(e) =>
                                handleCellChange(
                                  rowIndex,
                                  slot.id,
                                  "subject",
                                  e.target.value
                                )
                              }
                              disabled={!row.section}
                            >
                              {getAvailableSubjects(
                                row.className,
                                row.section
                              ).map((subject, idx) => (
                                <MenuItem key={idx} value={subject}>
                                  {subject}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl size="small">
                            <InputLabel>Teacher</InputLabel>
                            <Select
                              value={periodData.teacher || ""}
                              label="Teacher"
                              onChange={(e) =>
                                handleCellChange(
                                  rowIndex,
                                  slot.id,
                                  "teacher",
                                  e.target.value
                                )
                              }
                              disabled={!row.section}
                            >
                              {teachers.map((teacher) => (
                                <MenuItem key={teacher.id} value={teacher.id}>
                                  {teacher.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <TextField
                            size="small"
                            label="Room"
                            value={periodData.room || ""}
                            onChange={(e) =>
                              handleCellChange(
                                rowIndex,
                                slot.id,
                                "room",
                                e.target.value
                              )
                            }
                            disabled={!row.section}
                          />
                        </Box>
                      </TableCell>
                    );
                  })}
                  <TableCell />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={!isDirty || loading}
        >
          {loading ? <CircularProgress size={24} /> : "Save Timetable"}
        </Button>
      </Box>

      {/* Confirmation dialog for deleting a period */}
      <Dialog open={deleteDialogOpen} onClose={handleCancelDelete}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this period? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TimeTableEditor;

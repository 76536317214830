import React from "react";
import { Link } from "react-router-dom";
import "./Css/RegisterHeader.css";
import chequeredFlag from "../Assets/chequered-flag.png";
import ReachUs from "./ReachUs";

const RegisterHeader = () => {
  return (
    <div className="register-header">
      <h1>
        <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
          SpellStudy
        </Link>
      </h1>

      <ReachUs />
      {/* <img src={chequeredFlag} alt="chequered flag" /> */}
    </div>
  );
};

export default RegisterHeader;

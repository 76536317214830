import React, { useState, useEffect } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import {
  receivedStudentListState,
  teacherSelectedExamState,
  teacherSelectedClassState,
  teacherSelectedSectionState,
  teacherSelectedDateState,
  teacherSelectedSubjectState,
} from "../Atoms";
import baseUrl from "../Configs";

import {
  Box,
  TextField,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from "@mui/material";

const ExamRating = ({ onSubmit }) => {
  const [studentwiseScore, setStudentwiseScore] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [examList, setExamList] = useState([]);
  const [maxMarks, setMaxMarks] = useState("");
  const teacherSelectedClass = useRecoilValue(teacherSelectedClassState);
  const teacherSelectedSection = useRecoilValue(teacherSelectedSectionState);
  const teacherSelectedDate = useRecoilValue(teacherSelectedDateState);
  const teacherSelectedSubject = useRecoilValue(teacherSelectedSubjectState);
  const teacherSelectedExam = useRecoilValue(teacherSelectedExamState);
  const [receivedStudentList, setReceivedStudentListState] = useRecoilState(
    receivedStudentListState
  );
  const [selectedExam, setSelectedExam] = useRecoilState(
    teacherSelectedExamState
  );
  const [selectedClass, setSelectedClass] = useRecoilState(
    teacherSelectedClassState
  );
  const [selectedSection, setSelectedSection] = useRecoilState(
    teacherSelectedSectionState
  );
  const [selectedSubject, setSelectedSubject] = useRecoilState(
    teacherSelectedSubjectState
  );
  const teacherName = "Joshiya Nanda"; // replace with actual teacher name

  const addTestReportUrl = `${baseUrl}/v1/studentroute/updatetestreport`;
  const getExamsUrl = `${baseUrl}/v1/classroute/getexamsbyclassandsection?classname=${selectedClass}&section=${selectedSection}`;

  const options = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const handleExamChange = (event) => {
    setSelectedExam(event.target.value);
  };

  // Prepopulate studentwiseScore with existing test report data if available.
  useEffect(() => {
    setStudentwiseScore(
      receivedStudentList.map((student) => {
        // Default values if no report exists for this subject
        let prepopulatedScore = "";
        let prepopulatedRemark = "";
        let existingScore = null;
        let existingRemark = null;

        // Check if the student has any testReport and if both exam and subject are selected
        if (
          student.testReport &&
          teacherSelectedExam &&
          teacherSelectedSubject
        ) {
          // Find a test report matching the selected exam.
          const examReport = student.testReport.find(
            (rep) => rep.testName === teacherSelectedExam
          );
          if (examReport && Array.isArray(examReport.report)) {
            // Find a report within that exam matching the selected subject.
            const subjectReport = examReport.report.find(
              (r) => r.subject === teacherSelectedSubject
            );
            if (subjectReport && subjectReport.report) {
              prepopulatedScore =
                subjectReport.report.score !== null
                  ? subjectReport.report.score
                  : "";
              prepopulatedRemark = subjectReport.report.remark || "";
              existingScore = subjectReport.report.score;
              existingRemark = subjectReport.report.remark;
            }
          }
        }
        return {
          rollNumber: student.rollNumber,
          report: {
            score: prepopulatedScore,
            remark: prepopulatedRemark,
            existingScore: existingScore,
            existingRemark: existingRemark,
          },
        };
      })
    );
  }, [receivedStudentList, teacherSelectedExam, teacherSelectedSubject]);

  const handleScoreChange = (index, score) => {
    const newScores = [...studentwiseScore];
    newScores[index].report.score = score;
    setStudentwiseScore(newScores);
  };

  const handleRemarkChange = (index, remark) => {
    const newScores = [...studentwiseScore];
    newScores[index].report.remark = remark;
    setStudentwiseScore(newScores);
  };

  const fetchStudents = async () => {
    const getStudentsUrl = `${baseUrl}/v1/classroute/getstudentlist?className=${teacherSelectedClass}&section=${teacherSelectedSection}`;
    const response = await fetch(getStudentsUrl, options);
    const data = await response.json();
    setReceivedStudentListState(data);
    console.log("Received student list: ", data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if required fields are selected.
    if (
      !teacherSelectedClass ||
      !teacherSelectedSection ||
      !teacherSelectedSubject ||
      !teacherSelectedExam ||
      maxMarks === "" ||
      studentwiseScore.length === 0
    ) {
      setOpenDialog(true);
      return;
    }

    // Filter out students if no score is entered or if the report remains unchanged.
    const modifiedReports = studentwiseScore.filter((student) => {
      // Skip if no score is entered.
      if (student.report.score === "") return false;

      // If there's prepopulated data, check if both score and remark remain unchanged.
      if (
        student.report.existingScore !== null &&
        student.report.score === student.report.existingScore &&
        student.report.remark === student.report.existingRemark
      ) {
        return false;
      }

      return true;
    });

    if (modifiedReports.length === 0) {
      console.log("No changes detected; nothing to update.");
      return;
    }

    const payload = {
      className: teacherSelectedClass,
      section: teacherSelectedSection,
      testName: teacherSelectedExam,
      date: teacherSelectedDate,
      subject: teacherSelectedSubject,
      takenBy: teacherName,
      maxMarks: maxMarks,
      testReports: modifiedReports.map(({ rollNumber, report }) => ({
        rollNumber,
        report: {
          score: report.score,
          remark: report.remark,
        },
      })),
    };

    const postOptions = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(payload),
    };

    fetch(addTestReportUrl, postOptions)
      .then((res) => res.json())
      .then((jsonData) => console.log("Response:", jsonData))
      .then(() => onSubmit())
      .catch((e) => console.log(e));
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const getExams = async () => {
    try {
      const response = await fetch(getExamsUrl, options);
      const data = await response.json();
      setExamList(data);
      console.log("Exam list:", data);
    } catch (error) {
      console.log("Error fetching exams:", error);
    }
  };

  useEffect(() => {
    if (selectedClass && selectedSection) getExams();
  }, [getExamsUrl]);

  useEffect(() => {
    if (
      receivedStudentList.length === 0 &&
      teacherSelectedClass &&
      teacherSelectedSection
    ) {
      fetchStudents();
    }
    console.log("Received student list: ", receivedStudentList);
  }, [teacherSelectedClass, teacherSelectedSection]);

  return (
    <Box sx={{ padding: 4, gap: 4, display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <FormControl fullWidth>
          <InputLabel id="exam-select-label">Select Exam</InputLabel>
          <Select
            labelId="exam-select-label"
            id="exam-select"
            value={selectedExam}
            onChange={handleExamChange}
          >
            {examList.map((exam, index) => (
              <MenuItem key={index} value={exam.examName}>
                {exam.examName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Max Marks"
          variant="outlined"
          fullWidth
          value={maxMarks}
          onChange={(e) => setMaxMarks(e.target.value)}
        />
      </Box>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          {receivedStudentList.map((student, index) => (
            <Grid item xs={12} key={student.rollNumber}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Typography>{`${student.rollNumber} - ${student.name}`}</Typography>
                  <TextField
                    label={studentwiseScore[index]?.report.score ? "" : "Score"}
                    type="number"
                    variant="outlined"
                    value={studentwiseScore[index]?.report.score}
                    onChange={(e) => handleScoreChange(index, e.target.value)}
                    sx={{ marginRight: 2 }}
                  />

                  <TextField
                    label={
                      studentwiseScore[index]?.report.remark ? "" : "Remark"
                    }
                    variant="outlined"
                    value={studentwiseScore[index]?.report.remark}
                    onChange={(e) => handleRemarkChange(index, e.target.value)}
                    // InputLabelProps={{
                    //   shrink: !!studentwiseScore[index]?.report.remark,
                    // }}
                  />
                </div>
              </Box>
            </Grid>
          ))}
        </Grid>

        <Button
          variant="contained"
          color="primary"
          type="submit"
          sx={{ marginTop: 3 }}
        >
          Submit Scores
        </Button>
      </form>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Incomplete Information</DialogTitle>
        <DialogContent>
          <Typography>
            Please select the class, section, and subject before submitting the
            exam ratings.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ExamRating;

import React from "react";
import { RecoilRoot } from "recoil";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import "./Css/App.css";

import EntryPage from "./EntryPage";
import StudentTable2 from "./StudentTable2";
import AddStudent from "./AddStudent";
import Class from "./Teacher-app/Class";
import InputData from "./InputData";
import SelectClassView from "./Teacher-app/SelectClassView";
import TeacherHome from "./Teacher-app/TeacherHome";
import AdminTasks from "./AdminTasks";
import AddClass from "./AddClass";
import MasterUserTasks from "./MasterUserTasks";
import SuperUserHome from "./SuperUserHome";
import ThankyouPage from "./ThankyouPage";
import AddNewSyllabus from "./AddNewSyllabus";
import LiveSyllabus from "./Teacher-app/LiveSyllabus";
import StudentFeeStatus from "./StudentFee";
import SalaryStatus from "./SalaryStatus";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import CostManagement from "./CostManagement";
import StaffBio from "./StaffBio";
import EditStudentDetails from "./EditStudentDetails";
import ClasswiseStatus from "./ClasswiseStatus";
import PrivateRoute from "./PrivateRoute"; // Secure route wrapper
import EntryPage2 from "./EntryPage2";
import DownloadRecords from "./DownloadRecords";
import UserManagement from "./UserManagement";
import PrivacyPolicy from "./PrivacyPolicy";

function App() {
  return (
    <RecoilRoot>
      <BrowserRouter>
        <Routes>
          {/* Public Routes (No Authentication Needed) */}
          <Route path="/" element={<EntryPage2 />} />
          <Route path="/entrypage" element={<EntryPage2 />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/auth/reset-password/:token"
            element={<ResetPassword />}
          />
          <Route path="/thankyou" element={<ThankyouPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />

          {/* Protected Routes (Require Authentication) */}
          <Route element={<PrivateRoute />}>
            <Route path="/t" element={<StudentTable2 />} />
            <Route path="/input" element={<InputData />} />
            <Route path="/class" element={<Class />} />
            <Route path="/master" element={<MasterUserTasks />} />
            <Route path="/super" element={<SuperUserHome />} />
            <Route path="/selectclass" element={<SelectClassView />} />
            <Route path="/teacherhome" element={<TeacherHome />} />
            <Route path="/admintasks" element={<AdminTasks />} />
            <Route path="/addstudent" element={<AddStudent />} />
            <Route path="/addclass" element={<AddClass />} />
            <Route path="/add-new-syllabus" element={<AddNewSyllabus />} />
            <Route path="/livesyllabus" element={<LiveSyllabus />} />
            <Route path="/feeStatus" element={<StudentFeeStatus />} />
            <Route path="/salaryStatus" element={<SalaryStatus />} />
            <Route path="/costManagement" element={<CostManagement />} />
            <Route path="/staffbio" element={<StaffBio />} />
            <Route path="/downloadrecords" element={<DownloadRecords />} />
            <Route path="/usermanagement" element={<UserManagement />} />
            <Route
              path="/editstudentdetails"
              element={<EditStudentDetails />}
            />
            <Route path="/classwisestatus" element={<ClasswiseStatus />} />
          </Route>

          {/* Catch-All Route (404 Page) */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </RecoilRoot>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  TextField,
  Button,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Box,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import BounceLoader from "react-spinners/ClipLoader";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import baseUrl from "./Configs";

const EditStudentDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [studentData, setStudentData] = useState(null); // Stores the fetched student data
  const [classes, setClasses] = useState([]);
  const { studentId } = useParams(); // Assume the route includes the student ID

  const navigate = useNavigate();

  useEffect(() => {
    const fetchStudentData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${baseUrl}/v1/studentroute/getstudent/${studentId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        const data = await response.json();
        setStudentData(data); // Store the data to be used as prefilled values
      } catch (error) {
        console.error("Failed to fetch student data:", error);
        alert("Failed to fetch student data. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    const fetchClasses = async () => {
      try {
        const response = await fetch(
          `${baseUrl}/v1/classroute/getavailableclasses`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        const data = await response.json();
        setClasses(groupClasses(data));
      } catch (error) {
        console.error("Failed to fetch classes:", error);
        alert("Failed to fetch classes. Please try again.");
      }
    };

    fetchStudentData();
    fetchClasses();
  }, [studentId]);

  const groupClasses = (classes) => {
    return classes.reduce((groupedClasses, cls) => {
      const classIndex = groupedClasses.findIndex(
        (groupedClass) => groupedClass.className === cls.className
      );

      if (classIndex === -1) {
        groupedClasses.push({
          className: cls.className,
          sectionsAndSubjects: [
            { section: cls.section, subjects: cls.subjects },
          ],
        });
      } else {
        groupedClasses[classIndex].sectionsAndSubjects.push({
          section: cls.section,
          subjects: cls.subjects,
        });
      }
      return groupedClasses;
    }, []);
  };

  const handleInputChange = (field, value) => {
    setStudentData({ ...studentData, [field]: value });
  };

  const handleParentChange = (index, field, value) => {
    const updatedParents = [...studentData.parentsInfo];
    updatedParents[index][field] = value;
    setStudentData({ ...studentData, parentsInfo: updatedParents });
  };

  const updateStudentData = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${baseUrl}/v1/studentroute/editstudent/${studentId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify(studentData),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to update student data.");
      }
      alert("Student data updated successfully!");
      navigate("/students"); // Redirect or refresh as needed
    } catch (error) {
      console.error("Error updating student data:", error);
      alert("Failed to update student data.");
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading || !studentData) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
        <BounceLoader color="#4A90E2" loading={isLoading} size={60} />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        {/* Student Information */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Edit Student Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel id="class-select-label">Class</InputLabel>
                <Select
                  labelId="class-select-label"
                  value={studentData.className}
                  onChange={(e) =>
                    handleInputChange("className", e.target.value)
                  }
                >
                  {classes.map((cls, index) => (
                    <MenuItem key={index} value={cls.className}>
                      {`Class ${cls.className}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel id="section-select-label">Section</InputLabel>
                <Select
                  labelId="section-select-label"
                  value={studentData.section}
                  onChange={(e) => handleInputChange("section", e.target.value)}
                  disabled={!studentData.className}
                >
                  {studentData.className &&
                    classes
                      .find((cls) => cls.className === studentData.className)
                      ?.sectionsAndSubjects.map((sAs, index) => (
                        <MenuItem key={index} value={sAs.section}>
                          {sAs.section}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Full Name"
                variant="filled"
                fullWidth
                value={studentData.name || ""}
                onChange={(e) => handleInputChange("name", e.target.value)}
              />
            </Grid>
            {/* Repeat for other fields like gender, admission number, bus details, etc. */}
          </Grid>
        </Grid>

        {/* Guardians Information */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Edit Guardians Information
          </Typography>
          {studentData.parentsInfo.map((parent, index) => (
            <Box
              key={index}
              sx={{
                border: "1px solid #ccc",
                borderRadius: "8px",
                p: 2,
                mb: 2,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Relation"
                    variant="filled"
                    fullWidth
                    value={parent.relation}
                    onChange={(e) =>
                      handleParentChange(index, "relation", e.target.value)
                    }
                  />
                </Grid>
                {/* Repeat for other guardian fields like name, email, etc. */}
              </Grid>
            </Box>
          ))}
        </Grid>
      </Grid>

      {/* Submit Button */}
      <Box sx={{ mt: 4, textAlign: "center" }}>
        <Button variant="contained" color="primary" onClick={updateStudentData}>
          Save Changes
        </Button>
      </Box>
    </Box>
  );
};

export default EditStudentDetails;

import React from "react";
import "./Css/ShowStudentBio.css";

const ShowStudentBio = ({ data }) => {
  return (
    <div className="student-bio table-component">
      {data.name && (
        <div className="student-bio__name">
          <h3>{data.name}</h3>
        </div>
      )}

      {data.dob && (
        <div className="student-bio__details__item">
          <span>DOB: </span>
          <span>{data.dob}</span>
        </div>
      )}

      {(data.className || data.section) && (
        <div className="student-bio__details__item">
          <span>Class: </span>
          <span>
            {data.className} {data.section}
          </span>
        </div>
      )}

      {/* {data.attendance && (
        <div className="student-bio__details__item">
          <span>Attendance: </span>
          <span>{data.attendance}</span>
        </div>
      )} */}

      {data.parents
        ? data.parents.map((parent, index) => (
            <>
              <div key={index} className="student-bio__details__item">
                <span>
                  {parent.relation.charAt(0).toUpperCase() +
                    parent.relation.slice(1)}
                  's Name:{" "}
                </span>
                <span>{parent.parent.name}</span>
              </div>
              <div key={index} className="student-bio__details__item">
                <span>
                  {parent.relation.charAt(0).toUpperCase() +
                    parent.relation.slice(1)}
                  's Occupation:{" "}
                </span>
                <span>
                  {parent.parent.occupation &&
                    parent.parent.occupation.charAt(0).toUpperCase() +
                      parent.parent.occupation.slice(1)}
                </span>
              </div>
              <div key={index} className="student-bio__details__item">
                <span>
                  {parent.relation.charAt(0).toUpperCase() +
                    parent.relation.slice(1)}
                  's Education:{" "}
                </span>
                <span>
                  {parent.parent.education &&
                    parent.parent.education.charAt(0).toUpperCase() +
                      parent.parent.education.slice(1)}
                </span>
              </div>
              <div key={index} className="student-bio__details__item">
                <span>
                  {parent.relation.charAt(0).toUpperCase() +
                    parent.relation.slice(1)}
                  's Number:{" "}
                </span>
                <span>{parent.parent.phoneNumber}</span>
              </div>
              <div key={index} className="student-bio__details__item">
                <span>
                  {parent.relation.charAt(0).toUpperCase() +
                    parent.relation.slice(1)}
                  's Email:{" "}
                </span>
                <span>{parent.parent.email}</span>
              </div>
            </>
          ))
        : null}

      {data.address && (
        <div className="student-bio__details__item">
          <span>Address: </span>
          <span>{data.address}</span>
        </div>
      )}

      {data.fatherPhoneNumber && (
        <div className="student-bio__details__item">
          <span>Father's Number: </span>
          <span>{data.fatherPhoneNumber}</span>
        </div>
      )}

      {data.motherPhoneNumber && (
        <div className="student-bio__details__item">
          <span>Mother's Number: </span>
          <span>{data.motherPhoneNumber}</span>
        </div>
      )}

      {data.guardianName && (
        <div className="student-bio__details__item">
          <span>Guardian's Name: </span>
          <span>{data.guardianName}</span>
        </div>
      )}

      {data.guardianPhoneNumber && (
        <div className="student-bio__details__item">
          <span>Guardian's Number: </span>
          <span>{data.guardianPhoneNumber}</span>
        </div>
      )}

      {data.guardianAddress && (
        <div className="student-bio__details__item">
          <span>Guardian Address: </span>
          <span>{data.guardianAddress}</span>
        </div>
      )}

      {data.email && (
        <div className="student-bio__details__item">
          <span>Email: </span>
          <span>{data.email}</span>
        </div>
      )}

      {data.admissionDate && (
        <div className="student-bio__details__item">
          <span>Admission Date: </span>
          <span>{data.admissionDate}</span>
        </div>
      )}

      {data.guardianRelation && (
        <div className="student-bio__details__item">
          <span>Guardian Relation: </span>
          <span>{data.guardianRelation}</span>
        </div>
      )}

      {data.admissionNumber && (
        <div className="student-bio__details__item">
          <span>Admission Number: </span>
          <span>{data.admissionNumber}</span>
        </div>
      )}

      {data.busService && (
        <div className="student-bio__details__item">
          <span>Bus Service: </span>
          <span>{data.busService}</span>
        </div>
      )}

      {data.busNumber && (
        <div className="student-bio__details__item">
          <span>Bus Number: </span>
          <span>{data.busNumber}</span>
        </div>
      )}

      {data.busStop && (
        <div className="student-bio__details__item">
          <span>Bus Stop: </span>
          <span>{data.busStop}</span>
        </div>
      )}

      {data.previousSchool && (
        <div className="student-bio__details__item">
          <span>Previous School: </span>
          <span>{data.previousSchool}</span>
        </div>
      )}
    </div>
  );
};

export default ShowStudentBio;
